import React from 'react'

const CheckBoxField = ({ input, label, placeholder, required, meta: { touched, error }, toggle }) => (
    <div className='input-wrap checkbox'>
        { toggle && (input.value ? toggle(true) : toggle(false)) }
        <div className='input-container'>
            <input { ...input } placeholder={ placeholder } type='checkbox' />
            <label><p>{ label }{ required ? '*' : '' }</p> { touched && error && <span>{ error }</span> }</label>
        </div>
    </div>
)

export { CheckBoxField }
