import React, { Component } from 'react'
import styled from 'styled-components'
import { devices } from '../../Common'

class QA extends Component {
    state = {
        qa: this.props.qa,
        open: false,
    }

    static getDerivedStateFromProps(props, state) {
        if (props.qa && props.qa !== state.qa) state.qa = props.qa
        return state
    }

    toggleQuestion = () => this.setState({ open: !this.state.open })

    render() {
        return (
            <QABlock key={ this.state.qa.question }>
                <Question onClick={ this.toggleQuestion }><Arrow open={ this.state.open }/>{ this.state.qa.question }</Question>
                <Answer open={ this.state.open } dangerouslySetInnerHTML={{ __html: this.state.qa.answer }}/>
            </QABlock>
        )
    }
}

const QABlock = styled.div`
    margin-bottom: 25px;
    max-width: 740px;
`

const Question = styled.div`
    font-weight: 600;
    cursor: pointer;
    transition: opacity 0.3s ease-in;

    &:hover {
        opacity: 0.5;
    }

    @media ${devices.mobile} {
        margin-left: 20px;
    }
`

const Arrow = styled.div`
    background: center / contain no-repeat url(/static/images/angle-down.svg);
    width: 15px;
    height: 15px;
    position: absolute;
    left: -20px;
    transition: transform 0.1s ease-in;
    transform: rotate(${props => props.open ? '' : '-90deg'});
    @media ${devices.mobile} {
        left: -3px;
    }
`

const Answer = styled.div`
    margin-top: 8px;
    margin-left: 22px;
    display: ${props => props.open ? 'block' : 'none' };
`

export { QA }
