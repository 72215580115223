import { combineReducers } from 'redux'

import { content } from './content.reducers'
import { event } from './event.reducers'
import { exhibition } from './exhibition.reducers'
import { form as formField } from './form.reducers'
import { menu } from './menu.reducers'
import { reducer as form } from 'redux-form'
import { survivors } from './survivors.reducers'



const rootReducer = combineReducers({
    menu,
    content,
    exhibition,
    event,
    survivors,
    form,
    formField,
})

export default rootReducer
