import React from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import { devices, margins, paddings, Container } from './Common'
import { ArrowLink } from './ArrowLink'
import colors from '../../css/colors.scss'


const Block = styled.div`
    ${margins}
    ${paddings}
    &:before, &:after {
        content: '';
        display: table;
        clear: both;
    }
`

const Bottom = styled.div`
    height: 1px;
    ${ArrowLink} {
        * { font-size: 15px; }
        float: right;
        img { width: 200px }
    }
    @media ${devices.mobile} {
        margin-top: 40px;
        margin-bottom: 50px;
        ${ArrowLink} {
            * { font-size: 14px; }
            float: right;
            img { width: 160px }
        }
    }
`

// TODO: fix comp for use in simple carousel
const ImageCard = styled.div`
    width: 100%;
    height: ${({ height=400 }) => height}px;
    background: center / ${props => props.orientation || 'cover' } no-repeat url(${props => props.background});
    @media ${devices.mobile} {
        height: ${({ height=200 }) => height}px;
    }
`

const SlideImageCardComp = styled(ImageCard)`
    height: 440px;
    margin-bottom: 0;
    background-position: top;
    @media ${devices.mobile} {
        height: 300px;
    }
`

const SlideCaption = styled.div`
    font-size: 8px;
    padding-top: 10px;
    padding-right: 40%;
`

const SlideImageCard = styled(({caption, background}) => (
    <div>
        <SlideImageCardComp background={ background } />
        { caption && <SlideCaption>{ caption }</SlideCaption> }
    </div>
))``

const Section = styled(Container)`
    padding-top: 12px;
    padding-bottom: 10px;
    margin-top: ${({ margintop=0 }) => margintop}px;
    margin-bottom: ${({ marginbottom=0 }) => marginbottom}px;
`

const StyledCarousel = styled(Carousel)`
    .carousel-slider {
        overflow: initial !important;
    }
    .carousel .slide {
        background: transparent;
        text-align: left;
    }
    .control-arrow {
        background: none !important;
        opacity: 1 !important;
        &:before {
            border: none !important;
            margin: 0;
            height: 40px;
            width: 18px;
        }
        &.control-prev {
            left: -37px !important;
            @media ${devices.mobile} {
                left: 0 !important;
            }
            &:before {
                background: center / contain no-repeat url('/static/images/slider-arrow-left.svg');
            }
        }
        &.control-next {
            right: -37px !important;
            @media ${devices.mobile} {
                right: 0 !important;
            }
            &:before {
                background: center / contain no-repeat url('/static/images/slider-arrow-right.svg');
            }
        }
    }
`

const ArticleWrap = styled.div`
    max-width: ${props => props.maxwidth || 'unset'};
    padding-bottom: 30px;
    @media ${devices.mobile} {
        padding-left: 0;
        padding-right: 0;
    }
`

const ArticleTextWrap = styled.div`
    ${margins}
    ${props => !props.nopadding && 'padding-right: 60px;'}
    overflow: hidden;
    p:not(:last-of-type) {
        margin-bottom: 18px;
    }
    ul {
        padding: 5px 0px 5px 50px;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: auto !important;
        th, td {
            padding: 5px;
            border: 1px solid ${colors.darkgray};
        }
        &[border='0'] {
            th, td {
                border: 0;
            }
        }
    }
    @media (min-width: 1100px) {
        // margin-right: ${props => props.shorten ? '400px' : '0'};
    }
    @media ${devices.mobile} {
        padding-right: 0;
    }
    li {
        margin-bottom: 5px;
    }
`

const Hr = styled.div`
    ${margins}
    width: 100%;
    height: 1px;
    background-color: #a0a0a1;
`

const ArticleInfo = styled(Block)`
    padding: 36px 25px;
    border: 1px solid ${colors.darkgray};
`


export {
    Block,
    Bottom,
    ImageCard,
    SlideImageCard,
    StyledCarousel,
    ArticleWrap,
    ArticleInfo,
    Hr,
    Section,
    ArticleTextWrap,
}
