exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n", ""]);

// exports
exports.locals = {
	"darkblue": "#0d2d6c",
	"gradient_bluegreen": "#164a6f",
	"blue": "#006f79",
	"gradient_greenblue": "#23817e",
	"gradient_green": "#519885",
	"gradient_green2": "#67a389",
	"green": "#6ba48a",
	"spinner_greenblue": "#3b777a",
	"spinner_greenblue2": "#578b82",
	"yellow": "#989747",
	"purple": "#5b325e",
	"darkgray": "#414042",
	"black": "#000",
	"white": "#fff"
};