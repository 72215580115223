import { createConstants } from '../_helpers/utils'

export const formTypes = createConstants(
    'GET_GROUP_FORM_REQUEST',
    'GET_GROUP_FORM_SUCCESS',
    'POST_GROUP_FORM_REQUEST',
    'POST_GROUP_FORM_SUCCESS',
    'POST_GROUP_FORM_FAILURE',
    'GET_VOLUNTEER_FORM_REQUEST',
    'GET_VOLUNTEER_FORM_SUCCESS',
    'POST_VOLUNTEER_FORM_REQUEST',
    'POST_VOLUNTEER_FORM_SUCCESS',
    'POST_VOLUNTEER_FORM_FAILURE',
    'GET_JOB_APPLICATION_FORM_REQUEST',
    'GET_JOB_APPLICATION_FORM_SUCCESS',
    'POST_JOB_APPLICATION_FORM_REQUEST',
    'POST_JOB_APPLICATION_FORM_SUCCESS',
    'POST_JOB_APPLICATION_FORM_FAILURE',
    'RESET',
)
