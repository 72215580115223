import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

class VideoModal extends Component {
    state = {
        showModal: this.props.showModal,
        url: this.props.url,
        closeModal: this.props.closeModal,
        playing: true,
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            showModal: nextProps.showModal,
            url: nextProps.url,
            closeModal: nextProps.closeModal,
            playing: true,
        })
   }


    render() {
        return (
            <Modal show={ this.state.showModal }>
                <CloseContainer>
                    <Close role='button' aria-label='Close' onClick={ this.state.closeModal }>&#xd7;</Close>
                </CloseContainer>
                <ReactPlayer url={ this.state.url } playing={ this.state.showModal } height='100vh' width='100vw' controls={ true }/>
            </Modal>
        )
    }
}

const Modal = styled.div`
    height: 100vh;
    width: 100vw;
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;

    ${({ show }) => show ? '' : 'display: none;'}

    iframe {
        height: calc(100vh - 40px) !important;
        width: 100vw;
    }
`

const CloseContainer = styled.div`
    height: 40px;
    width: 40px;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
`

const Close = styled.div`
    color: white;
    position: absolute;
    right: 16px;
    font-size: 35px;
`

export { VideoModal }
