import React, { Component } from 'react'
import {
    Block,
    gradient,
    Grid,
    devices,
    ArticleTextWrap,
} from '../../Common'
import styled from 'styled-components'
import colors from '../../../css/colors.scss'

class ThreeColumnsVisit extends Component {
    state = {
        block: this.props.block || {}
    }

    static getDerivedStateFromProps(props, state) {
        if (props.block && props.block !== state.block) state.block = props.block
        return state
    }

    render() {
        return (
            <div>
                <GridBorderedColumns margintop={ 0 } marginbottom={ 0 }>
                    <BorderedColumn tabletWidth={ 100 } marginbottom={ 20 } padding={ '20px 30px 0px' } maxwidth='unset'>
                        <ColumnArticleTextWrap>
                            <VisitBlock marginbottom={ 20 }>
                                <div dangerouslySetInnerHTML={{ __html: this.state.block.content }}/>
                            </VisitBlock>
                        </ColumnArticleTextWrap>
                    </BorderedColumn>
                    <BorderedColumn tabletWidth={ 100 } marginbottom={ 20 } padding={ '20px 30px 0px' } maxwidth='unset'>
                        <ColumnArticleTextWrap>
                            <VisitBlock marginbottom={ 20 }>
                                <div dangerouslySetInnerHTML={{ __html: this.state.block.content2 }}/>
                            </VisitBlock>
                        </ColumnArticleTextWrap>
                    </BorderedColumn>
                    <BorderedColumn tabletWidth={ 100 } marginbottom={ 20 } padding={ '20px 30px 0px' } maxwidth='unset'>
                        <ColumnArticleTextWrap>
                            <VisitBlock marginbottom={ 20 }>
                                <div dangerouslySetInnerHTML={{ __html: this.state.block.content3 }}/>
                            </VisitBlock>
                        </ColumnArticleTextWrap>
                    </BorderedColumn>
                </GridBorderedColumns>
            </div>
        )
    }
}

class ThreeColumnsEducation extends Component {
    state = {
        block: this.props.block || {}
    }

    static getDerivedStateFromProps(props, state) {
        if (props.block && props.block !== state.block) state.block = props.block
        return state
    }

    render() {
        return (
            <div>
                <GridBorderedColumns margintop={ 40 }>
                    <BorderedColumn tabletWidth={ 100 } marginbottom={ 20 } padding={ '20px' }>
                        <ColumnArticleTextWrap>
                            <EducationBlock marginbottom={ 20 }>
                                <div dangerouslySetInnerHTML={{ __html: this.state.block.content }}/>
                            </EducationBlock>
                        </ColumnArticleTextWrap>
                    </BorderedColumn>
                    <BorderedColumn tabletWidth={ 100 } marginbottom={ 20 } padding={ '20px' }>
                        <ColumnArticleTextWrap>
                            <EducationBlock marginbottom={ 20 }>
                                <div dangerouslySetInnerHTML={{ __html: this.state.block.content2 }}/>
                            </EducationBlock>
                        </ColumnArticleTextWrap>
                    </BorderedColumn>
                    <BorderedColumn tabletWidth={ 100 } marginbottom={ 20 } padding={ '20px' }>
                        <ColumnArticleTextWrap>
                            <EducationBlock marginbottom={ 20 }>
                                <div dangerouslySetInnerHTML={{ __html: this.state.block.content3 }}/>
                            </EducationBlock>
                        </ColumnArticleTextWrap>
                    </BorderedColumn>
                </GridBorderedColumns>
            </div>
        )
    }
}

const BorderedColumn = styled(Grid.Column)`
    min-width: 300px;
    background-color: ${colors.white};
    padding: ${props => props.padding || '30px'};
    flex-basis: 0;
    flex-grow: 1;
    margin-left: 0px;
    margin-right: 20px;
    max-width: ${props => props.maxwidth || '300px'};
    padding-bottom: 0;
    padding-top: 20px;
    &:first-of-type {
        margin-left: 0;
    }
    &:last-of-type {
        margin-right: 0;
    }
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: -1px;
        z-index: -2;
        ${gradient};
    }
    @media (max-width: 1040px) {
        &:last-of-type {
            margin-right: 20px;
        }
    }
    @media ${devices.mobile} {
        margin-right: 0 !important;
        max-width: unset;
    }
`

const ColumnArticleTextWrap = styled(ArticleTextWrap)`
    padding-right: 0;
`

const GridBorderedColumns = styled(Grid)`
    max-width: 1400px;
`

const EducationBlock = styled(Block)`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const VisitBlock = styled(Block)`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1400px;

    .color-hr {
        width: 60%;
    }
`

export { ThreeColumnsVisit, ThreeColumnsEducation }
