import React, { useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'
import { getDonationsList, toggleHeaderAndFooter } from '../../_actions'
import colors from '../../css/colors.scss'
import { Grid, devices, Button, minDevices, Em } from '../Common'
import { ResponsiveVideo } from '../Content'
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share'
import Ticker from 'react-ticker'
import { Link } from 'react-router-dom'
import { Logo } from '../Global/Header'
import { Sponsor } from '../'
import DocumentMeta from 'react-document-meta'
// import moment from 'moment'

export const VideoPage = ({ title, meta, specialEvent }) => {
    // Gradient background
    document.body.style.background = `linear-gradient(
        63deg,
        ${colors.darkblue} 0%,
        ${colors.blue} 60%,
        ${colors.green} 100%
    )`

    const intervalTime = 60000 // 1 minute
    const dispatch = useDispatch()
    const { donations } = useSelector(state => state.content, shallowEqual) // This acts as mapStateToProps

    useEffect(() => {
        // get donor info
        dispatch(toggleHeaderAndFooter(true))
        const updateDonations = () => dispatch(getDonationsList())

        updateDonations() // run on component mount

        // update at specified interval
        let updateInterval = setInterval(updateDonations, intervalTime)

        // the return acts as componentWillUnmount
        // here we clear the update interval and reset the background color
        return () => {
            clearInterval(updateInterval)
            dispatch(toggleHeaderAndFooter(false))
            document.body.style.background = 'white'
        }
    }, [])

    const shareUrl = window.location.href
    const style = (name) => ({
        float: 'left',
        cursor: 'pointer',
        marginRight: `${name === 'mail' ? '0' : '10px'}`,
        background: `center / contain no-repeat url(/static/images/${name}.svg)`,
    })

    const { title_line_1,
        title_line_2,
        video_url,
        start_time,
        placeholder_image,
        fundraising_objective,
        fundraising_goal,
        how_you_can_help,
        sponsors,
    } = specialEvent

    // Show video if current time is after start time
    // const showVideo = moment().isAfter(start_time)

    return (
        <React.Fragment>
            <DocumentMeta {...meta} />
            <VideoPageGrid>
                <Grid.Column width={15}></Grid.Column>
                <Grid.Column width={60}>
                    <LogoWrap as={Link} to='/'>
                        <Logo src='/static/images/hmh-logo.svg' role='button' aria-label='Home' />
                    </LogoWrap>
                </Grid.Column>
                <Grid.Column width={15}></Grid.Column>
            </VideoPageGrid>
            <VideoPageGrid>
                {/* Sponsors */}
                <BorderColumn width={15} style={{ paddingTop: '0' }} marginbottom={52} show={sponsors.length > 0}>
                    {sponsors.length > 0 &&
                        <Sponsor
                            name={'A Special Thanks to Our Sponsor:'}
                            plural={'A Special Thanks to Our Sponsors:'}
                            sponsors={sponsors}
                            showImage={true}
                            imageWidth={50}
                            center={true}
                        />
                    }
                </BorderColumn>
                {/* Video, title, share buttons */}
                <Grid.Column width={60}>
                    <ResponsiveVideo url={video_url} />

                    <EventTitle>
                        {title_line_1 && <h3>{title_line_1.toUpperCase()}</h3>}
                        {title_line_2 && <h2>{title_line_2.toUpperCase()}</h2>}
                    </EventTitle>

                    <ShareBox>
                        <h4>Share</h4>
                        <div>
                            <FacebookShareButton
                                style={style('facebook')}
                                url={shareUrl}
                                quote={title}
                                role='button'
                                aria-label='Share on FaceBook'
                            />
                            <TwitterShareButton
                                style={style('twitter')}
                                url={shareUrl}
                                title={title}
                                role='button'
                                aria-label='Share on Twitter'
                            />
                            <EmailShareButton
                                style={style('mail')}
                                url={shareUrl}
                                subject={title}
                                role='button'
                                aria-label='Share by email'
                            />
                        </div>
                    </ShareBox>
                </Grid.Column>
                <BorderColumn width={15} marginbottom={52} show={fundraising_goal || fundraising_objective}>
                    <div>
                        {fundraising_objective &&
                            <>
                                <Em>Our Fundraising Objective:</Em>
                                <p>{fundraising_objective}</p>
                                <br /><br />
                            </>
                        }
                        {fundraising_goal &&
                            <>
                                <Em>Our Goal:</Em>
                                <p>{fundraising_goal}</p>
                                <br /><br />
                            </>
                        }
                        {how_you_can_help &&
                            <>
                                <Em>How you can help:</Em>
                                <p>{how_you_can_help}</p>
                            </>
                        }
                    </div>
                    {specialEvent.donate_link &&
                        <DonateButton
                            as={'a'}
                            href={specialEvent.donate_link}
                            target='_blank'
                            rel='noopener noreferrer'
                        >
                            DONATE
                    </DonateButton>
                    }
                </BorderColumn>
            </VideoPageGrid>
            <VideoPageGrid style={{ paddingBottom: '53px' }}>
                {/* Donor Count */}
                {specialEvent.show_donor_count ? (
                    <BorderColumn width={15} className='donor-count'>
                        {donations.length ?
                            <div>
                                <p>DONOR COUNT</p>
                                <h1>{donations.length}</h1>
                            </div>
                            : ''}
                    </BorderColumn>
                ) : ''}
                <Grid.Column width={60} className='event-description'>
                    {specialEvent.header_text && <h3 style={{ marginBottom: '10px' }}>{specialEvent.header_text}</h3>}
                    {specialEvent.description && <Description dangerouslySetInnerHTML={{ __html: specialEvent.description }} />}
                </Grid.Column>
                <Grid.Column width={15} className='empty'></Grid.Column>
            </VideoPageGrid>
            {donations.length ?
                <Ticker offset="run-in">
                    {() =>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            {donations.map((d, i) => <Donor key={i}>{`${d.name} •`}&nbsp;</Donor>)}
                        </div>
                    }
                </Ticker>
                : ''}
        </React.Fragment>
    )
}

const LogoWrap = styled.div`
    display: flex;
    padding: 10px 25px 15px 0;
    @media ${devices.tablet} {
        padding: 3px 20px 10px 20px;
    }
`

const Description = styled.div`
    a {
        color: white;
    }
`

const Donor = styled.p`
    color: white;
    font-size: 25px;
    white-space: nowrap;
    font-family: "proxima-nova", sans-serif;
`

const VideoPageGrid = styled(Grid)`
    max-width: 1400px;
    color: white;
    margin: 0 auto;
    justify-content: space-evenly;

    .donor-count {
        text-align: center;
    }

    @media ${devices.tablet} {
        /* flex-direction: column; */
        ${Grid.Column} {
            &:nth-child(2) {
                order: -1;
            }
        }
        align-items: center;

        .empty {
            display: none;
        }
    }

    @media ${devices.mobile} {
        .event-description {
            margin: 0 30px;
        }
    }
`

const BorderColumn = styled(Grid.Column)`
    ${({ show = true }) => show && `border: 1px solid white;`}
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* margin-bottom: 52px; */

    @media ${devices.tablet} {
        align-items: center;
        justify-content: space-evenly;
        margin: 30px auto;
        width: 80%;
        align-self: flex-end;

        .donor-count & {
            padding: 0;
        }
    }
`

const ShareBox = styled.div`
    border: 1px solid white;
    width: 185px;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
    margin-top: 10px;
    height: auto;

    h4 {
        margin-right: 10px;
    }

    @media ${devices.tablet} {
        width: 50%;
        margin-right: 3%;
        margin-bottom: 20px;
    }
`

const EventTitle = styled.div`
    background-color: white;
    width: 100%;
    color: black;
    text-align: center;
    font-size: 25px;
    padding: 10px 30px;
    margin-top: 0;
    mix-blend-mode: screen; /* cutout text effect. Doesn't work on IE or Edge */

    @media ${devices.tablet} {
        h3 {font-size: 20px;}
        h2 {font-size: 25px;}
    }
`

const DonateButton = styled(Button)`
    background-color: white;
    color: black;
    font-weight: bold;
    box-shadow: none;
    border: none;
    width: 100%;
    border-radius: 25px;
    mix-blend-mode: screen;
    padding: 15px 11px 14px;

    @media ${minDevices.desktop} {
        font-size: 20px;
    }

    @media ${devices.tablet} {
        margin-top: 20px;
    }
`
