import React, { Component } from 'react'
import styled from 'styled-components'
import { UpperHeader } from '../../Common'

class Biography extends Component {
    state = {
        subBlocks: this.props.subBlocks || [],
        title: this.props.title,
    }

    static getDerivedStateFromProps(props, state) {
        if (props.subBlocks && props.subBlocks !== state.subBlocks) state.subBlocks = props.subBlocks
        if (props.title && props.title !== state.title) state.title = props.title
        return state
    }

    render() {
        return (
            <BioContainer>
                { this.state.title ? <UpperHeader marginbottom='20'>{ this.state.title }</UpperHeader> : '' }
                {this.state.subBlocks.map(e => (
                    <Bio key={ e.name }>
                        <BioImage image={ e.image }/>
                        <div>
                            <Name>{ e.name }</Name>
                            <BioBody dangerouslySetInnerHTML={{ __html: e.body }} />
                        </div>
                    </Bio>
                ))}
            </BioContainer>
        )
    }
}

const BioBody = styled.div`

`

const Name = styled.div`
    font-weight: 700;
`

const BioImage = styled.div`
    height: 230px;
    width: 150px;
    float: left;
    margin-right: 15px;
    background: center / contain no-repeat url(${props => props.image});
`

const BioContainer = styled.div`

`

const Bio = styled.div`
    margin-bottom: 40px;

    p {
        margin-bottom: 10px;
    }
`



export { Biography }
