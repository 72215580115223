import React from 'react'
import Dropzone from 'react-dropzone'
import styled from 'styled-components'

const File = ({ fileName, label, required, uploadFile, file, uploadText, meta: { touched, error }, input: { onChange } }) => (
    <div className='input-wrap'>
        <label>{ label }{ required ? '*' : '' } { touched && error && <span>{ error }</span> }</label>
        <Dropzone onDrop={ file => uploadFile(fileName, file, onChange) }>
            {({ getRootProps, getInputProps }) => (
                <Drop>
                    <div { ...getRootProps() }>
                        <input { ...getInputProps() } />
                        <p>{ file.length ? file[0].name : uploadText }</p>
                    </div>
                </Drop>
            )}
        </Dropzone>
    </div>
)

const Drop = styled.section`
    cursor: pointer;
`

export { File }
