import React from 'react'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import 'react-widgets/dist/css/react-widgets.css'

if (document.documentElement.lang === 'es') moment.locale('es')
momentLocalizer()

class TimePicker extends DateTimePicker {
    constructor(props) {
        super(props)
        this.state = {
            open: this.props.open || false,
        }
    }

    render() {
        return (
            <DateTimePicker
                { ...this.props }
                onClick={ this._handleInputClick.bind(this, this.props.onClick) }
                onToggle={ this._handleToggle.bind(this, this.props.onToggle) }
                open={ this.state.open }
                min={ moment().hour(9).minute(0).toDate() }
                max={ moment().hour(15).minute(0).toDate() }
                format='h:mm a'
                date={ false }
                autoComplete='tour-time'
            />
        )
    }

    _handleInputClick(callback, event) {
        if (event.target.tagName === 'INPUT') this._open(true)
        return callback && callback(event)
    }

    _handleToggle(callback, view) {
        this._open(!this.state.open, view)
        return callback && callback()
    }

    _open(shouldOpen) {
        const open = shouldOpen ? 'time' : false
        this.setState({ open })
    }
}


const TimeField = ({ input: { onChange },label, required, meta: { touched, error } }) => (
    <div className='input-wrap'>
        <label>{ label }{ required ? '*' : '' } { touched && error && <span>{ error }</span> }</label>
        <div className='input-container'>
            <TimePicker onChange={ onChange } />
        </div>
    </div>
)

export { TimeField }
