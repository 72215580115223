import React from 'react'

const RadioGroup = ({ input, label, required, options, meta: { touched, error } }) => (
    <div className='input-wrap radio'>
        <label>{ label }{ required ? '*' : '' } { touched && error && <span>{ error }</span> }</label>
        <div className='input-container'>
            {options.map(e => (
                <label key={ e.value }>
                    <input type='radio' { ...input } value={ e.value } checked={ e.value === input.value } />
                    { e.title }
                </label>
            ))}
        </div>
    </div>
)

export { RadioGroup }
