import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { devices } from '../../Common'
import { getElemHeight } from '../../../_helpers'

export const ThumbnailGrid = ({ subBlocks }) => {
    const scroll = (position) => {
        const header = document.getElementById('header-container')
        const intro = document.getElementById('content-intro')
        if (header) position += getElemHeight(header)
        if (intro) position += getElemHeight(intro)
        if (window.location.pathname === '/give/ways-to-give/') position += 60 // TODO: why does the give page need 60 extra?
        window.scrollTo({
            top: position,
            left: 0,
            behavior: 'smooth'
        })
    }

    return (
        <GridContainer>
            {subBlocks.map(e => (
                <GridItem
                    key={e.image}
                    as={e.external_url || !e.url ? 'a' : e.hash_link ? HashLink : Link}
                    to={e.external_url ? '' : e.hash_link ? `${e.url}#${e.hash_link.toLowerCase().replace(/ /g, '_')}` : e.url}
                    scroll={e.hash_link ? el => scroll(el.offsetTop) : ''}
                    href={e.external_url ? e.external_url : ''}
                >
                    <GridImage image={e.image} />
                    <GridCategory>{e.category}</GridCategory>
                    <GridTitle>{e.title}</GridTitle>
                </GridItem>
            ))}
        </GridContainer>
    )
}

const GridTitle = styled.h3`
    font-size: ${({ fontSize = 30 }) => fontSize}px;
    color: ${({ color = 'inherit' }) => color};
    font-weight: 600;
    font-family: 'neue-haas-grotesk-display';
    max-width: 350px;
    @media ${devices.mobile} {
       font-size: 20px;
    }
`

const GridItem = styled.div`
    margin-bottom: 50px;
    text-decoration: none;
    color: inherit;
    @media ${devices.mobile} {
        max-width: 100%;
        margin-bottom: 10px;
    }
`

const GridImage = styled.div`
    width: 350px;
    height: 350px;
    background-repeat: no-repeat;
    margin-right: 20px;
    background: url(${props => props.image});
    background-size: cover;
    background-position: center;
    @media ${devices.mobile} {
        background-repeat: no-repeat;
        background-size: contain;
        max-width: 100%;
    }
`

const GridCategory = styled.h2`
    text-transform: uppercase;
    font-family: 'Financier', sans-serif;
    font-size: 20px;
    letter-spacing: 2px;
    font-weight: 200;
    margin: 10px 0 7px;
`

const GridContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 1400px;
`