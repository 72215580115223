import { createConstants } from '../_helpers/utils'

export const exhibitionTypes = createConstants(
    'EXHIBITION_LIST_REQUEST',
    'EXHIBITION_LIST_SUCCESS',
    'EXHIBITION_PAGE_REQUEST',
    'EXHIBITION_PAGE_SUCCESS',

    'CLEAR_EXHIBITION',
)
