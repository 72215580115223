import styled from 'styled-components'
import colors from '../../css/colors.scss'
import { Image } from './Common'

export const MuseumModal = styled.div`
    height: 100vh;
    width: 100vw;
    display: ${props => props.show ? 'block' : 'none'};
`

export const ModalBackground = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 10000;
    opacity: ${props => props.show ? 1 : 0};
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    background: rgba(${colors.black}, 0.65);
    transition: all 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Modal = styled.div`
    visibility: ${props => props.show ? 'visible' : 'hidden'};
    backface-visibility: ${props => props.show ? 'visible' : 'hidden'};
    transform: ${props => props.show ? 'translateX(-50%) translateY(-50%)' : 'translateX(-50%) translateY(-70%)'};
    transition: transform 0.3s;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    max-width: 1000px;
    min-width: 320px;
    height: auto;
    z-index: 1000000;
    background: linear-gradient(
        145deg,
        ${colors.darkblue} 0%,
        ${colors.blue} 35.8%,
        ${colors.green} 100%
    );
    color: ${colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: neue-haas-grotesk-display;
`

export const Title = styled.div`
    font-size: 25px;
    text-align: center;
    max-width: 400px;
    font-weight: 400;
    letter-spacing: 1px;
`

export const Close = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    height: 50px;
    width: 50px;
    z-index: 10000000000;

    &:before, &:after {
        content: '';
        position: absolute;
        width: 30px;
        height: 2px;
        background-color: white;
        top: 25px;
        right: 10px;
    }

    &:before{
        transform: rotate(45deg);
    }
    &:after{
        transform: rotate(-45deg);
    }
`

export const Info = styled.div`
    text-align: center;
    font-size: 19px;
    margin-bottom: 35px;
    font-weight: 300;
    letter-spacing: 0.8px;
    max-width: 630px;

    a {
        color: white !important;
    }
`

export const HR = styled.div`
    border-bottom: 2px solid white;
    margin: 30px 0;
    width: 15%;
`

export const Logo = styled(Image)`
    height: 55px;
    width: 180px;
    margin: 40px 0 30px;
`