import { createConstants } from '../_helpers/utils'

export const eventTypes = createConstants(
    'EVENT_LIST_REQUEST',
    'EVENT_LIST_SUCCESS',
    'EVENT_PAGE_REQUEST',
    'EVENT_PAGE_SUCCESS',

    'CLEAR_EVENT_LIST',
    'CLEAR_EVENT',
)
