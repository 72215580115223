import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter, Redirect } from 'react-router-dom'
import styled from 'styled-components'
import {
    Container,
    Gradient,
    Image,
    Button,
    Input,
    UnstyledLink,
    minDevices,
    devices,
} from '../Common'
import {
    loadHeaderLinks,
    loadMenuLinks,
    search,
    toggleMenu,
    toggleSearch,
    updateQuery,
} from '../../_actions'
import colors from '../../css/colors.scss'
import { HashLink } from 'react-router-hash-link';


class Header extends Component {
    state = {
        openNavItemId: undefined,
        submit: false,
        error: '',
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(loadMenuLinks())
        dispatch(loadHeaderLinks())
    }

    changeInput = (e) => this.setState({ [e.target.name]: e.target.value })

    search = (e) => {
        const { dispatch, query } = this.props
        let { boilerplate } = this.props
        boilerplate = boilerplate.NAV || boilerplate
        e.preventDefault()
        if (query) {
            this.setState({ submit: true })
            dispatch(search(query))
            {/* BOILERPLATE NAV SEARCH_ERROR 'No results found' */}
            setTimeout(() => this.setState({ error: boilerplate.SEARCH_ERROR }), 5000);
        }
    }

    openMenuPanel = () => {
        const { dispatch } = this.props
        document.body.classList.add('disable-scroll');
        dispatch(toggleMenu(true))
        dispatch(toggleSearch(false))
        this.setState({ openNavItemId: null })
    }

    openSearchPanel = () => {
        const { dispatch } = this.props
        document.body.classList.add('disable-scroll');
        dispatch(toggleMenu(true))
        dispatch(toggleSearch(true))
        dispatch(updateQuery(''))
        this.setState({ openNavItemId: null })
    }

    closePanel = () => {
        const { dispatch } = this.props
        document.body.classList.remove('disable-scroll');
        dispatch(toggleMenu(false))
        dispatch(toggleSearch(false))
        this.setState({ openNavItemId: null })
    }

    toggleNavItem = (id) => () => {
        this.setState({ openNavItemId: id === this.state.openNavItemId ? null : id })
    }

    changeLocation = (location) => (e) => {
        e.preventDefault()
        if (!location) return
        if (location !== this.props.history.location.pathname) {
            this.props.history.push(location)
        }
        document.body.classList.remove('disable-scroll');
        this.props.dispatch(toggleMenu(false))
        this.setState({ openNavItemId: null })
    }

    closeMenu = (location) => (e) => {
        if (!location || e.metaKey) return
        document.body.classList.remove('disable-scroll');
        this.props.dispatch(toggleMenu(false))
        this.setState({ openNavItemId: null })
    }

    render() {
        const { menuOpen, searchOpen, menuLinks, ie, searchResults, dispatch, query, headerLinks } = this.props
        const panelOpen = menuOpen || searchOpen
        let { boilerplate } = this.props
        boilerplate = boilerplate.NAV || boilerplate
        const spanish = document.documentElement.lang === 'es'
        let spanishURL = 'https://es.hmh.org'
        if (window.location.host === 'localhost:8000') spanishURL = 'http://spanish:8000'
        if (window.location.host === 'staging.hmh.org') spanishURL = 'https://spanish-staging.hmh.org'

        let englishURL = 'https://hmh.org'
        if (window.location.host === 'spanish:8000') englishURL = 'http://localhost:8000'
        if (window.location.host === 'spanish-staging.hmh.org') englishURL = 'https://staging.hmh.org'

        const translatedSiteURL = spanish ? englishURL : spanishURL

        return (
            <FixedMenu open={ panelOpen }>
                <Menu>
                    <HeaderContainer>
                        <LogoWrap as={ Link } to='/' onClick={ this.closeMenu('/') }>
                            <Logo src='/static/images/hmh-logo.svg' role='button' aria-label='Home' />
                        </LogoWrap>
                        <MobileMenuLanguage>
                            <MenuItem as='a' href={ translatedSiteURL }>
                                { spanish ? 'eng' : 'esp' }
                            </MenuItem>
                        </MobileMenuLanguage>
                        <InnerMenu>
                            <MenuItem as='a' role='button' aria-label='Search' hideMobile={ !panelOpen } onClick={ this.openSearchPanel }>
                                <Image src='/static/images/search.svg' height={ 25 } width={ 25 } aria-label='Search' />
                            </MenuItem>
                            <DesktopMenu>
                                {headerLinks && headerLinks.filter(e => e.position === 'LEFT').map(e => (
                                    <MenuItem key={ e.linked_page } as={ Link } to={ e.linked_page } onClick={ this.closeMenu(e.linked_page) }>
                                        { e.link_title }
                                    </MenuItem>
                                ))}
                                {headerLinks && headerLinks.filter(e => e.position === 'CENTER').map(e => (
                                    <MenuItem key={ e.linked_page } as={ Link } to={ e.linked_page } onClick={ this.closeMenu(e.linked_page) }>
                                        { e.link_title }
                                    </MenuItem>
                                ))}
                                {headerLinks && headerLinks.filter(e => e.position === 'RIGHT').map(e => (
                                    <MenuItem key={ e.linked_page } as={ Link } to={ e.linked_page } onClick={ this.closeMenu(e.linked_page) }>
                                        { e.link_title }
                                    </MenuItem>
                                ))}
                                <MenuItem as='a' href={ translatedSiteURL }>
                                    { spanish ? 'eng' : 'esp' }
                                </MenuItem>
                                <InnerMenu>
                                    <SocialItem as='a' aria-label={ `${boilerplate.HMH} Twitter` } href='https://twitter.com/HMHou?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor' target='_blank' rel='noopener noreferrer' ie={ ie }>
                                        <Image src='/static/images/twitter.svg' height={ 18 } aria-label='Twitter' />
                                    </SocialItem>
                                    <SocialItem as='a' aria-label={ `${boilerplate.HMH} Facebook` } href='https://www.facebook.com/HOLOCAUST-MUSEUM-HOUSTON-78039800708/' target='_blank' rel='noopener noreferrer' ie={ ie }>
                                        <Image src='/static/images/facebook.svg' height={ 18 } aria-label='Facebook' />
                                    </SocialItem>
                                    <SocialItem as='a' aria-label={ `${boilerplate.HMH} Instagram` } href='https://www.instagram.com/hmhou/?hl=en' target='_blank' rel='noopener noreferrer' ie={ ie }>
                                        <Image src='/static/images/instagram.svg' height={ 18 } aria-label='Instagram' />
                                    </SocialItem>
                                </InnerMenu>
                            </DesktopMenu>
                            <MenuItem onClick={ panelOpen ? this.closePanel : this.openMenuPanel }>
                                <MobileMenuButton open={ panelOpen } />
                                <MenuButton color='white' onClick={ panelOpen ? this.closePanel : this.openMenuPanel } larger={ spanish && panelOpen }>
                                    {/* BOILERPLATE NAV BACK 'BACK' */}
                                    {/* BOILERPLATE NAV MENU 'MENU' */}
                                    { panelOpen ? boilerplate.BACK : boilerplate.MENU }
                                </MenuButton>
                            </MenuItem>
                        </InnerMenu>
                    </HeaderContainer>
                </Menu>
                { this.state.submit && !searchOpen && query && searchResults.length ? <Redirect to={ `/${spanish ? 'buscar' : 'search'}/${query}` } /> : '' }
                {searchOpen ? (
                    <SearchWrap>
                        <SearchContainer style={{ display: 'flex', justifyContent: 'center' }}>
                            <SearchClose onClick={ panelOpen ? this.closePanel : this.openMenuPanel }>
                                <MobileMenuButton open={ panelOpen } />
                                <MenuButton color='white' onClick={ this.closePanel } larger={ spanish }>
                                    {/* BOILERPLATE NAV BACK 'BACK' */}
                                    { boilerplate.BACK }
                                </MenuButton>
                            </SearchClose>
                            <SearchInputWrap onSubmit={ this.search }>
                                <SubmitInput type='submit' onClick={ this.search } value=''/>
                                {/* BOILERPLATE NAV SEARCH 'Search' */}
                                <Input placeholder={ boilerplate.SEARCH } defaultValue={ query } onChange={ (e) => dispatch(updateQuery(e.target.value)) } />
                            </SearchInputWrap>
                            {this.state.submit && !searchResults.length ? (
                                <SearchError>
                                    { this.state.error }
                                </SearchError>
                            ) : ''}
                        </SearchContainer>
                    </SearchWrap>
                ) : ''}
                <NavigationWrap open={ panelOpen }>
                    <MobileMenu>
                        {headerLinks.filter(e => e.position === 'LEFT').map(e => (
                            <MenuItem key={ e.linked_page } as={ Link } to={ e.linked_page } onClick={ this.closeMenu(e.linked_page) }>
                                { e.link_title }
                            </MenuItem>
                        ))}
                        {headerLinks.filter(e => e.position === 'CENTER').map(e => (
                            <MenuItem key={ e.linked_page } as={ Link } to={ e.linked_page } onClick={ this.closeMenu(e.linked_page) }>
                                { e.link_title }
                            </MenuItem>
                        ))}
                        {headerLinks.filter(e => e.position === 'RIGHT').map(e => (
                            <MenuItem key={ e.linked_page } as={ Link } to={ e.linked_page } onClick={ this.closeMenu(e.linked_page) }>
                                { e.link_title }
                            </MenuItem>
                        ))}
                    </MobileMenu>

                    <Container>
                        {menuLinks.filter(link => !link.parent).map(link =>
                            <NavigationItem
                                key={ link.id }
                                header={ link.title }
                                headerLink={ link.linked_page }
                                changeLocation={ this.changeLocation }
                                closeMenu={ this.closeMenu }
                                toggle={ this.toggleNavItem(link.id) }
                                open={ this.state.openNavItemId === link.id }
                                contentItems={ menuLinks.filter(l => l.parent === link.id) }
                                URL={ link.external_url }
                            />
                        )}
                    </Container>

                    <MobileMenu>
                        {/* BOILERPLATE NAV HMH 'Holocaust Museum Houston' */}
                        <SocialItem as='a' aria-label={ `${boilerplate.HMH} Twitter` } href='https://twitter.com/HMHou?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor' target='_blank' rel='noopener noreferrer'>
                            <Image src='/static/images/twitter.svg' height={ 18 } aria-label='Twitter' />
                        </SocialItem>
                        <SocialItem as='a' aria-label={ `${boilerplate.HMH} Facebook` } href='https://www.instagram.com/hmhou/?hl=en' target='_blank' rel='noopener noreferrer'>
                            <Image src='/static/images/facebook.svg' height={ 18 } aria-label='Facebook' />
                        </SocialItem>
                        <SocialItem as='a' aria-label={ `${boilerplate.HMH} Instagram` } href='https://www.instagram.com/hmhou/?hl=en' target='_blank' rel='noopener noreferrer'>
                            <Image src='/static/images/instagram.svg' height={ 18 } aria-label='Instagram' />
                        </SocialItem>
                    </MobileMenu>
                </NavigationWrap>
            </FixedMenu>
        )
    }
}

class NavigationItem extends Component {
    render() {
        return (
            <NavItem>
                {this.props.URL ? (
                    <NavItemHeader>
                        <UnstyledLink href={ this.props.URL } target='_blank' rel='noopener noreferrer'>
                            { this.props.header }
                        </UnstyledLink>
                    </NavItemHeader>
                ) : (
                    <React.Fragment>
                        <NavItemHeader onClick={ this.props.toggle } open={ this.props.open }>
                            <div onClick={ this.props.changeLocation(this.props.headerLink) }>
                                { this.props.header }
                            </div>
                        </NavItemHeader>
                        {this.props.contentItems.length > 0 &&
                            <NavItemContent open={ this.props.open } style={{ columnCount: `${this.props.contentItems.length > 3 ? 2 : 1}` }}>
                                {this.props.contentItems.map(item =>
                                    <NavItemContentItem key={ item.id }>
                                        <UnstyledLink
                                            as={ item.external_url ? 'a' : item.linked_page_hash ? HashLink : Link }
                                            to={ item.linked_page_hash ? `${item.linked_page}#${item.linked_page_hash}` : item.linked_page }
                                            href={ item.external_url }
                                            onClick={ this.props.closeMenu(item.linked_page) }
                                            rel={ item.external_url ? 'noopener noreferrer' : '' }
                                            target={ item.external_url ? '_blank' : '' }
                                        >
                                            { item.title }
                                        </UnstyledLink>
                                    </NavItemContentItem>
                                )}
                            </NavItemContent>
                        }
                    </React.Fragment>
                )}
            </NavItem>
        )
    }
}

const Menu = styled.div`
    display: flex;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    position: fixed;
`

const NavItem = styled.div`
    position: relative;
    color: ${colors.white};
    margin-bottom: 5px;
    display: flex;
    flex-direction: column;
    &:last-child {
        margin-bottom: 0;
    }
    @media ${devices.mobile} {
        margin-bottom: 3px;
        padding-left: 26px;
    }
`

const NavItemHeader = styled.div`
    display: table;
    cursor: pointer;
    padding-left: 40px;
    font-size: 34px;
    font-weight: 600;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &:before {
        content: "";
        display: block;
        width: 27px;
        height: 27px;
        background: url('/static/images/plus.svg') no-repeat 0 0;
        position: absolute;
        left: 0px;
        top: 7px;
        transform: translate3d(0,0,0) ${props => props.open ? 'rotate(45deg)' : 'rotate(0)'};
        transition: transform .2s ease-in-out;
    }
    div {
        display: inline;
    }
    @media ${devices.tablet} {
        font-size: 28px;
    }
    @media ${devices.mobile} {
        padding-left: 0;
        font-size: 24px;
        &:before {
            width: 15px;
            height: 15px;
            background-size: 15px 15px;
            top: 9px;
        }
        ${Image} {
            height: 15px;
            top: 9px;
        }
    }
`

const NavItemContent = styled.div`
    max-width: 670px;
    margin: 10px 0;
    column-count: 2;
    column-width: 300px;
    column-gap: 20px;
    padding-left: 42px;
    display: ${({ open }) => open ? 'block' : 'none'};

    @media ${devices.mobile} {
        padding-left: 0;
    }
`

const NavItemContentItem = styled.div`
    display: inline-block;
    width: 100%;
    margin: 3px 0;
    font-size: 22px;
    @media ${devices.mobile} {
        font-size: 15px;
    }
`

const FixedMenu = styled(Gradient.withComponent('header'))`
    height: ${({ open }) => open ? '100vh' : '80px'};
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    transition: height 0.35s;
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(
            180deg,
            ${colors.darkblue} 0px,
            ${colors.gradient_bluegreen} 30px,
            ${colors.blue} 80px,
            ${colors.gradient_greenblue} 25%,
            ${colors.gradient_green} 50%,
            ${colors.gradient_green2} 76.2%,
            ${colors.green} 100%);
        opacity: ${({ open }) => open ? '1' : '0'};
        transition: opacity 0.25s;
    }

    @media ${devices.tablet} {
        height: ${({ open }) => open ? '100vh' : '60px'};
    }

    @media ${devices.mobile} {
        &:before {
            background: linear-gradient(
                63deg,
                ${colors.darkblue} 0%,
                ${colors.blue} 60%,
                ${colors.green} 100%);
        }
    }
`

const HeaderContainer = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;
`

const DesktopMenu = styled.div`
    display: flex;
    @media ${devices.tablet} {
        display: none;
    }
`

const InnerMenu = styled.div`
    display: flex;
    margin-left: auto;
`

const MobileMenu = styled.div`
    display: none;
    flex-direction: row;
    justify-content: center;
    margin: 5vh 0;

    @media ${devices.tablet} {
        display: flex;
    }
`

const MobileMenuLanguage = styled(MobileMenu)`
    margin: 0;
    position: absolute;
    right: 70px;
    top: 6px;
`

const SearchWrap = styled.div`
    position: absolute;
    flex: 1;
    display: flex;
    height: 100vh;
    width: 100vw;
    z-index: 10000;
    align-content: center;
    justify-content: center;
    opacity: 0.8;

    background: linear-gradient(
        63deg,
        ${colors.darkblue} 0%,
        ${colors.blue} 60%,
        ${colors.green} 100%
    );

    @media ${minDevices.mobile} {
        background : linear-gradient(
            180deg,
            ${colors.darkblue} 0px,
            ${colors.gradient_bluegreen} 20px,
            ${colors.blue} 80px,
            ${colors.gradient_greenblue} 20%,
            ${colors.gradient_green} 30%,
            ${colors.gradient_green2} 76.2%,
            ${colors.green} 100%
        );
    }
`

const NavigationWrap = styled.div`
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 80px;
    padding: calc(10vh + 80px) 0 calc(10vh);
    overflow: hidden;
    overflow-y: scroll;
    transform: ${({ open }) => open ? `translate3d(0,0,0)` : `translate3d(0,-40px,0)`};
    opacity: ${({ open }) => open ? '1' : '0'};
    transition: transform 0.35s, opacity 0.25s;
    pointer-events: ${({ open }) => open ? 'auto' : 'none'};

    ${Container} {
        padding-bottom: 0;
    }

    @media (max-height: 800px) {
        padding: calc(60px) 0 calc(60px);
    }

    @media ${devices.tablet} {
        height: calc(100% - 60px);
        margin-top: 60px;
        padding: calc(5vh + 60px) 0 calc(5vh);
    }

    @media ${devices.mobile} {
        padding: 0 0 0;
    }
`

const SubmitInput = styled.input`
    height: 30px;
    width: 30px;
    background: center/contain no-repeat url(/static/images/search.svg);
    border: 0;
    z-index: 100000;
    cursor: pointer;
`

const SearchInputWrap = styled.form`
    position: relative;
    width: 70%;
    align-self: center;
    padding: 15px 0;
    border-bottom: 1px solid ${colors.white};
    ${SubmitInput} {
        position: absolute !important;
        left: 20px;
        top: 35px;
    }
    ${Input} {
        color: ${colors.white};
        font-size: 55px;
        width: 100%;
        padding: 0;
        padding-left: 70px;
        border: none;
        &::placeholder {
            color: white;
        }
        &:-internal-autofill-selected {
            color: white !important;
            background: none !important;
        }
    }
    @media ${devices.mobile} {
        width: 100%;
    }
`

export const Logo = styled(Image)`
    height: 60px;
    width: 180px;
    @media ${devices.tablet} {
        height: 40px;
        width: 120px;
    }
    @media ${devices.mobile} {
        height: 40px;
        width: 110px;
    }
`

const MenuButton = styled(Button)`
    width: ${props => props.larger ? 90 : 60}px;

    @media ${devices.mobile} {
        display: none !important;
    }
`

const MobileMenuButton = styled.div`
    height: 30px;
    width: 5px;
    margin-right: 15px;
    background: center / contain no-repeat url('/static/images/menu-dots.svg');
    ${({ open }) => open && 'transform: rotate(90deg);'}
    @media ${minDevices.mobile} {
        display: none;
    }
`

const MenuItem = styled.div`
    color: ${colors.white};
    text-transform: uppercase;
    text-decoration: none;
    font-size: 15px;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    text: center;
    ${({ hideMobile }) => hideMobile && `
        @media ${devices.tablet} {
            display: none;
        }
    `}
    @media ${minDevices.mobile} {
        &:last-child {
            padding-right: 25px !important;
        }
    }
`

const SearchClose = styled(MenuItem)`
    position: absolute;
    right: 15px;
    top: 12px;
    @media ${devices.tablet} {
        top: 0px;
        right: 0px;
    }
`

const LogoWrap = styled(MenuItem)`
    padding: 10px 25px 15px 25px;
    @media ${devices.mobile} {
        padding: 3px 20px 10px 20px;
    }
`

const SocialItem = styled(MenuItem)`
    padding-left: 10px;
    padding-right: 10px;
    @media ${minDevices.desktop} {
        &:first-child {
            margin-left: 18px;
        }
    }
    @media ${devices.tablet} {
        padding-left: 20px;
        padding-right: 20px;
        img {
            height: 28px;
        }
    }
    ${props => props.ie ? 'img { width: 30px; }' : ''}
`

const SearchError = styled.div`
    font-size: 40px;
    color: white;
    text-align: center;
    margin-top: 80px;
`

const SearchContainer = styled(Container)`
    flex-direction: column;
`

function mapStateToProps(state) {
    return {
        menuLinks: state.content.menuLinks,
        headerLinks: state.content.headerLinks,
        ie: state.content.ie,
        searchResults: state.content.searchResults,
        menuOpen: state.content.menuOpen,
        searchOpen: state.content.searchOpen,
        query: state.content.query,
        boilerplate: state.content.boilerplate,
    }
}

const connectedHeader = connect(mapStateToProps)(withRouter(Header))
export { connectedHeader as Header }
