import styled, { css } from 'styled-components'
import colors from '../../css/colors.scss'

const devices = {
    mobile: '(max-width: 480px)',
    tablet: '(max-width: 900px)',
    desktop: '(max-width: 1240px)',
    large: '(max-width: 2200px)',
    extraLarge: '(max-width: 3000px)',
}

const minDevices = {
    mobile: '(min-width: 480px)',
    tablet: '(min-width: 900px)',
    desktop: '(min-width: 1240px)',
    large: '(min-width: 2200px)',
    extraLarge: '(min-width: 3000px)',
}


const margins = css`
    margin-top: ${({ margintop=0 }) => margintop}px;
    margin-bottom: ${({ marginbottom=0 }) => marginbottom}px;
    @media ${devices.mobile} {
        ${props => props.margintop && `margin-top: ${({ margintop=0 }) => margintop / 2}px;`}
        ${props => props.marginbottom && `margin-bottom: ${({ marginbottom=0 }) => marginbottom / 2}px;`}
    }
`

const paddings = css`
    padding-top: ${({ paddingtop=0 }) => paddingtop}px;
    padding-bottom: ${({ paddingbottom=0 }) => paddingbottom}px;
    @media ${devices.mobile} {
        ${props => props.paddingtop && `padding-top: ${({ paddingtop=0 }) => paddingtop / 2}px;`}
        ${props => props.paddingbottom && `padding-bottom: ${({ paddingbottom=0 }) => paddingbottom / 2}px;`}
    }
`

const gradient = css`
    background: linear-gradient(
        63deg,
        ${colors.darkblue} 0%,
        ${colors.blue} 60%,
        ${colors.green} 100%
    );
`

const Gradient = styled.div`
    ${gradient}
`

const Container = styled.section`
    margin-left: auto;
    margin-right: auto;
    max-width: ${props => props.maxwidth || '1400px'};
    width: 100%;
    @media (max-width: 1450px) {
        padding-left: 50px;
        padding-right: 50px;
    }
    @media ${devices.mobile} {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 35px;
    }
`

const Image = styled.img`
    position: relative;
    display: block;
    vertical-align: middle;
`

const tabletWidthCss = css`
    @media ${devices.tablet} {
        width: ${({ tabletWidth=100 }) => tabletWidth}%;
    }
`

const GridColumn = styled.div`
    position: relative;
    display: inline-block;
    width: ${({ width=100 }) => width}%;
    ${({ floated }) => floated
        ? floated === 'right'
            ? 'margin-left: auto;'
            : 'margin-right: auto;'
        : ''
    }
    ${({ tabletWidth }) => tabletWidth && tabletWidthCss}
    @media ${devices.mobile} {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
    ${margins}
`

const tabletColumnsCss = css`
    @media ${devices.tablet} {
        width: ${({ columnsTablet=1 }) => 100/columnsTablet}%;
    }
`

const columnsCss = css`
    & > ${GridColumn} {
        width: ${({ columns=1, totalmargin }) => totalmargin ? `calc(${100/columns}% - ${Math.ceil(totalmargin/columns)}px)` : `${100/columns - 2}%`};
        ${({ columnsTablet }) => columnsTablet && tabletColumnsCss}
        @media ${devices.mobile} {
            width: 100%;
        }
    }
`

const Grid = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    ${props => props.width ? 'width:' + props.width + ';' : ''}
    ${margins}
    ${({ columns }) => columns && columnsCss}
`

Grid.Column = GridColumn

export {
    gradient,
    Gradient,
    Container,
    Image,
    Grid,
    minDevices,
    devices,
    margins,
    paddings,
}
