import { createConstants } from '../_helpers/utils'

export const menuTypes = createConstants(
    'GET_MENU_SUCCESS',
    'GET_MENU_ERROR',
    'TOGGLE_MENU',
    'TOGGLE_TODO_MENU',
    'TOGGLE_NEWSLETTER',
    'CHANGE_MENU_CSS',
)
