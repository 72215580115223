import { exhibitionTypes } from '../_constants'


const initialState = {
    list: [],
    page: {}
}

export function exhibition(state = initialState, action) {
    switch (action.type) {
        case exhibitionTypes.EXHIBITION_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload
            }
        case exhibitionTypes.EXHIBITION_PAGE_SUCCESS:
            return {
                ...state,
                page: action.payload
            }
        case exhibitionTypes.CLEAR_EXHIBITION:
            return {
                ...state,
                page: {}
            }
        default:
            return state
    }
}
