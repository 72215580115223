import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { TextField, SelectField, File } from './Inputs'
import { postJobApplicationForm } from '../../_actions'
import { UpperHeader } from '../Common'

class JobApplicationForm extends Component {
    state = {
        showStudent: false,
        states: this.props.states || [],
        positions: this.props.positions || [],
        position: this.props.position || {},
        boilerplate: this.props.boilerplate || {},
        cover_letter: {},
        resume: {},
        other: {},
        files: {
            cover_letter: {},
            resume: {},
            other: {},
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.states && props.states !== state.states) state.states = props.states
        if (props.positions && props.positions !== state.positions) state.positions = props.positions
        if (props.boilerplate && props.boilerplate !== state.boilerplate) state.boilerplate = props.boilerplate
        if (props.position && props.position !== state.position) {
            state.position = props.position
            props.initialize({ position: props.position.position })
        }
        return state
    }

    toggleStudent = show => this.setState({ showStudent: show })

    handleSubmit = (data) => {
        const { dispatch } = this.props
        const formData = new FormData()
        for (let key in data) {
            formData.append(key, data[key])
        }
        formData.append('cover_letter', data.cover_letter.file)
        formData.append('resume', data.resume.file)
        if (data.other) formData.append('other', data.other.file)

        dispatch(postJobApplicationForm(formData))
    }

    uploadFile = (file_name, file, onChange) => {
        const files = this.state.files
        files[file_name] = file
        const newFile = {
            file: file[0],
            name: file[0].name,
            size: file[0].size
        }
        this.setState({ files }, () => onChange(newFile))
    }

    required = value => {
        {/* BOILERPLATE FORM JOB_APPLICATION_VALIDATION_REQUIRED 'Required' */}
        return (value ? undefined : this.state.boilerplate.JOB_APPLICATION_VALIDATION_REQUIRED)
    }

    number = value => {
        {/* BOILERPLATE FORM JOB_APPLICATION_VALIDATION_NUMBER 'Must be a number' */}
        return value && isNaN(Number(value)) ? this.state.boilerplate.JOB_APPLICATION_VALIDATION_NUMBER : undefined
    }

    positiveNumber = value => {
        {/* BOILERPLATE FORM JOB_APPLICATION_VALIDATION_POSITIVE 'Must be a positive number' */}
        return value && value >= 0 ? undefined : this.state.boilerplate.JOB_APPLICATION_VALIDATION_POSITIVE
    }

    email = value => {
        {/* BOILERPLATE FORM JOB_APPLICATION_VALIDATION_EMAIL 'Invalid email address' */}
        return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            ? this.state.boilerplate.JOB_APPLICATION_VALIDATION_EMAIL
            : undefined
    }

    max250Words = value => {
        {/* BOILERPLATE FORM JOB_APPLICATION_VALIDATION_MAX_WORDW_250 'Your response cannot be more than 250 words.' */}
        return value && value.split(/ {2}| {1}/g).length - 1 <= 250
            ? undefined
            : this.state.boilerplate.JOB_APPLICATION_VALIDATION_MAX_WORDW_250
    }

    phoneNumber = value => {
        {/* BOILERPLATE FORM JOB_APPLICATION_VALIDATION_PHONE 'Invalid phone number, it must be 10 digits' */}
        return value && !/^(0|[1-9][0-9]{9})$/i.test(value)
            ? this.state.boilerplate.JOB_APPLICATION_VALIDATION_PHONE
            : undefined
    }

    zipcode = value => {
        {/* BOILERPLATE FORM JOB_APPLICATION_VALIDATION_ZIPCODE 'Invalid zipcode, it must be 5 digits' */}
        return value && !isNaN(Number(value)) && String(value).length === 5
            ? undefined
            : this.state.boilerplate.JOB_APPLICATION_VALIDATION_ZIPCODE
    }

    validate = values => {
        const errors = {}

        errors.first_name = this.required(values.first_name)
        errors.last_name = this.required(values.last_name)
        errors.email = this.email(values.email)
        errors.address = this.required(values.address)
        errors.city = this.required(values.city)
        errors.state = this.required(values.state)
        errors.zipcode = this.zipcode(values.zipcode)
        errors.primary_phone_number = this.phoneNumber(values.primary_phone_number)
        errors.cover_letter = this.required(values.cover_letter)
        errors.resume = this.required(values.resume)
        errors.reference_1_name = this.required(values.reference_1_name)
        errors.reference_1_email = this.required(values.reference_1_email)
        errors.reference_1_phone_number = this.phoneNumber(values.reference_1_phone_number)
        errors.reference_1_relationship = this.required(values.reference_1_relationship)
        errors.reference_2_name = this.required(values.reference_2_name)
        errors.reference_2_email = this.required(values.reference_2_email)
        errors.reference_2_phone_number = this.phoneNumber(values.reference_2_phone_number)
        errors.reference_2_relationship = this.required(values.reference_2_relationship)
        errors.reference_3_name = this.required(values.reference_3_name)
        errors.reference_3_email = this.required(values.reference_3_email)
        errors.reference_3_phone_number = this.phoneNumber(values.reference_3_phone_number)
        errors.reference_3_relationship = this.required(values.reference_3_relationship)

        return errors
    }

    submit = (data) => {
        const { dispatch } = this.props
        const formData = new FormData()
        for (let key in data) {
            formData.append(key, data[key])
        }

        const errors = this.validate(data)
        for (const key in errors) {
            {/* BOILERPLATE FORM JOB_VALIDATION_ERROR 'Please fill in all required fields.' */}
            const err = errors[key]
            if (err) {
                throw new SubmissionError({
                    [key]: err,
                    _error: this.state.boilerplate.GROUP_VALIDATION_ERROR
                })
            }
        }

        formData.append('cover_letter', data.cover_letter.file)
        formData.append('resume', data.resume.file)
        if (data.other) formData.append('other', data.other.file)

        dispatch(postJobApplicationForm(formData))
    }

    render() {
        const { handleSubmit, submitting, error, submitFailed, formData } = this.props
        const formErrors = submitFailed && formData && formData.syncErrors ? !!Object.keys(formData.syncErrors).length : false
        return (
            <form onSubmit={ handleSubmit(this.submit) }>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM JOB_APPLICATION_HEADER_POSITION 'Position' */}
                        <Field
                            name='position'
                            options={ this.state.positions }
                            component={ SelectField }
                            label={ this.state.boilerplate.JOB_APPLICATION_HEADER_POSITION }
                            required
                            validate={ [this.required] } />
                    </UpperHeader>
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM JOB_APPLICATION_HEADER_PERSONAL_INFORMATION 'Personal Information' */}
                        { this.state.boilerplate.JOB_APPLICATION_HEADER_PERSONAL_INFORMATION }
                    </UpperHeader>
                    <div className='input-group'>
                        {/* BOILERPLATE FORM JOB_APPLICATION_FIRST_NAME 'First Name' */}
                        <Field
                            name='first_name'
                            type='text'
                            component={ TextField }
                            label={ this.state.boilerplate.JOB_APPLICATION_FIRST_NAME }
                            required
                            validate={ [this.required] } />
                        {/* BOILERPLATE FORM JOB_APPLICATION_LAST_NAME 'Last Name' */}
                        <Field
                            name='last_name'
                            type='text'
                            component={ TextField }
                            label={ this.state.boilerplate.JOB_APPLICATION_LAST_NAME }
                            required
                            validate={ [this.required] } />
                    </div>
                    {/* BOILERPLATE FORM JOB_APPLICATION_CONTACT_EMAIL 'Email Address' */}
                    <Field
                        name='email'
                        type='email'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_CONTACT_EMAIL }
                        required
                        validate={ [this.required, this.email] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_ADDRESS 'Address' */}
                    <Field
                        name='address'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_ADDRESS }
                        required
                        validate={ [this.required] } />
                    <div className='input-group'>
                        {/* BOILERPLATE FORM JOB_APPLICATION_CITY 'City' */}
                        <Field
                            name='city'
                            type='text'
                            component={ TextField }
                            label={ this.state.boilerplate.JOB_APPLICATION_CITY }
                            required
                            validate={ [this.required] }     />
                        {/* BOILERPLATE FORM JOB_APPLICATION_STATE 'State' */}
                        <Field
                            name='state'
                            options={ this.state.states }
                            component={ SelectField }
                            label={ this.state.boilerplate.JOB_APPLICATION_STATE }
                            required
                            validate={ [this.required] }     />
                    </div>
                    {/* BOILERPLATE FORM JOB_APPLICATION_ZIPCODE 'Zip Code' */}
                    <Field
                        name='zipcode'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_ZIPCODE }
                        required
                        validate={ [this.required, this.zipcode] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_PHONE_NUMBER 'Phone Number' */}
                    <Field
                        name='primary_phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_PHONE_NUMBER }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERRED_BY 'Referred By' */}
                    <Field
                        name='referred_by'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERRED_BY } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_COVER_LETTER 'Cover Letter' */}
                    {/* BOILERPLATE FORM JOB_APPLICATION_UPLOAD_COVER_LETTER 'Upload Cover Letter' */}
                    <Field
                        name='cover_letter'
                        fileName='cover_letter'
                        file={ this.state.files.cover_letter }
                        component={ File }
                        uploadFile={ this.uploadFile }
                        label={ this.state.boilerplate.JOB_APPLICATION_COVER_LETTER }
                        uploadText={this.state.boilerplate.JOB_APPLICATION_UPLOAD_COVER_LETTER }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_RESUME 'Resume' */}
                    {/* BOILERPLATE FORM JOB_APPLICATION_UPLOAD_RESUME 'Upload Resume' */}
                    <Field
                        name='resume'
                        fileName='resume'
                        file={ this.state.files.resume }
                        component={ File }
                        uploadFile={ this.uploadFile }
                        label={ this.state.boilerplate.JOB_APPLICATION_RESUME }
                        uploadText={ this.state.boilerplate.JOB_APPLICATION_UPLOAD_RESUME }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_OTHER_FILE 'Other File' */}
                    {/* BOILERPLATE FORM JOB_APPLICATION_UPLOAD_OTHER 'Upload Other File' */}
                    <Field
                        name='other'
                        fileName='other'
                        file={ this.state.files.other }
                        component={ File }
                        uploadFile={ this.uploadFile }
                        label={ this.state.boilerplate.JOB_APPLICATION_OTHER_FILE }
                        uploadText={ this.state.boilerplate.JOB_APPLICATION_UPLOAD_OTHER } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM JOB_APPLICATION_HEADER_REFERENCES 'Professional References' */}
                        { this.state.boilerplate.JOB_APPLICATION_HEADER_REFERENCES }
                    </UpperHeader>
                    <UpperHeader marginbottom={ 10 } className='secondary-label'>
                        {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_1 'Professional Reference #1' */}
                        { this.state.boilerplate.JOB_APPLICATION_REFERENCE_1 }
                    </UpperHeader>
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_NAME 'Name' */}
                    <Field
                        name='reference_1_name'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_NAME }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_EMAIL 'Email' */}
                    <Field
                        name='reference_1_email'
                        type='email'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_EMAIL }
                        required
                        validate={ [this.required, this.email] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_PHONE 'Phone Number' */}
                    <Field
                        name='reference_1_phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_PHONE }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_RELATIONSHIP 'Relationship' */}
                    <Field
                        name='reference_1_relationship'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_RELATIONSHIP }
                        required
                        validate={ [this.required] } />
                    <UpperHeader marginbottom={ 10 } className='secondary-label'>
                        {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_2 'Professional Reference #2' */}
                        { this.state.boilerplate.JOB_APPLICATION_REFERENCE_2 }
                    </UpperHeader>
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_NAME 'Name' */}
                    <Field
                        name='reference_2_name'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_NAME }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_EMAIL 'Email' */}
                    <Field
                        name='reference_2_email'
                        type='email'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_EMAIL }
                        required
                        validate={ [this.required, this.email] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_PHONE 'Phone Number' */}
                    <Field
                        name='reference_2_phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_PHONE }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_RELATIONSHIP 'Relationship' */}
                    <Field
                        name='reference_2_relationship'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_RELATIONSHIP }
                        required
                        validate={ [this.required] } />
                    <UpperHeader marginbottom={ 10 } className='secondary-label'>
                        {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_3 'Professional Reference #3' */}
                        { this.state.boilerplate.JOB_APPLICATION_REFERENCE_3 }
                    </UpperHeader>
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_NAME 'Name' */}
                    <Field
                        name='reference_3_name'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_NAME }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_EMAIL 'Email' */}
                    <Field
                        name='reference_3_email'
                        type='email'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_EMAIL }
                        required
                        validate={ [this.required, this.email] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_PHONE 'Phone Number' */}
                    <Field
                        name='reference_3_phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_PHONE }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                    {/* BOILERPLATE FORM JOB_APPLICATION_REFERENCE_RELATIONSHIP 'Relationship' */}
                    <Field
                        name='reference_3_relationship'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.JOB_APPLICATION_REFERENCE_RELATIONSHIP }
                        required
                        validate={ [this.required] } />
                </FormGroup>
                <FormGroup>
                    <div className='form-validation-error'>
                        {error || formErrors ? (
                            <strong>{ error ? error : this.state.boilerplate.GROUP_VALIDATION_ERROR }</strong>
                        ) : ''}
                    </div>
                    <button type='submit' disabled={ submitting } className='button-gradient'>
                        {/* BOILERPLATE FORM JOB_APPLICATION_SUBMIT 'Submit Application' */}
                        <SubmitText>{ this.state.boilerplate.JOB_APPLICATION_SUBMIT }</SubmitText>
                    </button>
                    <div className='form-error'>
                        {/* BOILERPLATE FORM JOB_APPLICATION_ERROR 'There was an error submitting your request, please try again later.' */}
                        { this.state.boilerplate.JOB_APPLICATION_ERROR }
                    </div>
                </FormGroup>
            </form>
        )
    }
}

const FormGroup = styled.div`
    margin-bottom: 50px;
`

const SubmitText = styled.span`
`

const JobApplicationFormWithError = reduxForm({form: 'jobApplicationForm'})(JobApplicationForm)
const ConnectedJobApplicationFormWithError = connect(state => ({ formData: state.form.jobApplicationForm }))(JobApplicationFormWithError)

export { ConnectedJobApplicationFormWithError as JobApplicationForm }
