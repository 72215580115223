import React from 'react'
import styled from 'styled-components'
import colors from '../../css/colors.scss'

const Button = styled.button`
    background-color: transparent;
    text-transform: uppercase;
    font-size: 13px;
    box-shadow: 0px 0px 0px 1px ${({ color=colors.darkgray }) => color} inset;
    border: none;
    border-radius: 0;
    color: ${({ color=colors.darkgray }) => color};
    display: inline-block;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    text-shadow: none;
    outline: none;
    position: relative;
    user-select: none;
    padding: 9px 10px;
    line-height: 14px;

    ${({ inverted=false }) => inverted && `
        background-color: ${colors.darkgray};
        color: ${colors.white};
    `}
`

const Form = styled.form`
    position: relative;
    max-width: 100%;
    display: block;
`

const InputWrap = styled.div`
    ${props => props.width ? 'width: ' + props.width + ';' : ''}
    position: relative;
    display: inline-flex;
    input {
        margin: 0;
        max-width: 100%;
        box-shadow: none;
        flex: 1 0 auto;
        outline: none;
        border-radius: 0;
        background-color: transparent;
        padding: 4px 6px;
        border: 1px solid ${colors.darkgray};
    }
`

const Input = styled((props) => {
    return (
        <InputWrap width={ props.width }>
            <input { ...props } />
        </InputWrap>
    )
})``


export {
    Button,
    Input,
    Form,
}
