import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import {
    MuseumModal,
    Modal,
    Title,
    HR,
    Info,
    Close,
    ModalBackground,
    Logo,
} from '../Common/Modal'
import { devices } from '../Common'

export const AlertModal = () => {
    const targetRef = useRef(null)
    let targetElement = null
    
    const [showTicketModal, setShowTicketModal] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setShowTicketModal(true)
        }, 500)
        targetElement = targetRef.current
        disableBodyScroll(targetElement)
        return () => {
            clearAllBodyScrollLocks()
        }
    }, [])

    const closeModal = () => {
        enableBodyScroll(targetElement)
        setShowTicketModal(false)
    }

    return (
        <MuseumModal show={showTicketModal}>
            <Modal show={showTicketModal} className='test'>
                <ModalContent show={showTicketModal}>
                    <Logo src='/static/images/hmh-logo.svg' />
                    <Title>MUSEUM ADMISSIONS UPDATE</Title>
                    <HR />
                    <Info>Tickets are available for purchase online only.</Info>
                    <Tickets
                        href="https://9368a.blackbaudhosting.com/9368a/tickets?tab=3&txobjid=1b87ef14-b89d-4a5c-803f-79124cc2bcd8"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        PURCHASE TICKETS
                    </Tickets>
                </ModalContent>
                <Close title='Close (Esc)' className='mfp-close newsletter-close' onClick={closeModal} />
            </Modal>
            <ModalBackground show={showTicketModal} ref={targetElement} onClick={closeModal} />
        </MuseumModal>
    )
}

const ModalContent = styled.div`
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000000;
`

const Tickets = styled.a`
    text-decoration: none;
    text-transform: uppercase;
    border: 2px solid white;
    background: transparent;
    color: white;
    padding: 5px 40px;
    margin-bottom: 40px;
    text-align: center;
    font-weight: 300;
    font-size: 17px;
    letter-spacing: 2px;
    font-family: 'Financier';

    @media ${devices.mobile} {
        // for some reason this is the same size as 17px in MembersInfo
        font-size: 14px;
        padding: 5px 25px;
    }
`