import React from 'react'
import 'react-widgets/dist/css/react-widgets.css'
import SingleDatePicker from 'react-dates/lib/components/SingleDatePicker'
import 'react-dates/initialize'

class DatePicker extends React.Component {
    state = {
        focused: this.props.focused || false,
        min: this.props.min,
        date: null,
        label: this.props.label,
    }

    change = date => {
        this.setState({ date })
        this.props.onChange(date)
    }

    blocked = (date) => date < this.state.min

    render() {
        return (
            <SingleDatePicker
                date={ this.state.date }
                onDateChange={ date => this.change(date) }
                focused={ this.state.focused }
                onFocusChange={ ({ focused }) => this.setState({ focused }) }
                id={ this.state.label }
                numberOfMonths={ 1 }
                hideKeyboardShortcutsPanel={ true }
                isDayBlocked={ this.blocked }
                placeholder=''
            />
        )
        // return (
        //     <DateTimePicker
        //         { ...this.props }
        //         onClick={ this._handleInputClick.bind(this, this.props.onClick) }
        //         onToggle={ this._handleToggle.bind(this, this.props.onToggle) }
        //         open={ this.state.open }
        //         min={ this.state.min || new Date() }
        //         format='MM/DD/YYYY'
        //         time={ false }
        //     />
        // )
    }

    _handleInputClick(callback, event) {
        if (event.target.tagName === 'INPUT') this._open(true)
        return callback && callback(event)
    }

    _handleToggle(callback, view) {
        this._open(!this.state.open, view)
        return callback && callback()
    }

    _open(shouldOpen) {
        const open = shouldOpen ? 'date' : false
        this.setState({ open })
    }
}

const DateField = ({ input: { onChange }, label, required, min, meta: { touched, error } }) => (
    <div className='input-wrap'>
        <label>{ label }{ required ? '*' : '' } { !!touched && error && <span>{ error }</span> }</label>
        <div className='input-container'>
            <DatePicker onChange={ onChange } min={ min } label={ label } />
        </div>
    </div>
)

export { DateField }
