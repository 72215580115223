import React, { Component } from 'react'
import styled from 'styled-components'
import colors from '../../../css/colors.scss'

class Table extends Component {
    state = {
        subBlocks: this.props.subBlocks || { header: [], rows: [] }
    }

    render() {
        const { header, rows } = this.state.subBlocks
        return (
            <SimpleTable>
                <colgroup>
                    {header.map((e, i) =>
                        <col width={ e.width } key={ i } />
                    )}
                </colgroup>
                <thead>
                    {header.map((e, i) =>
                        <TableHeadItem key={ i }>{ e.name }</TableHeadItem>
                    )}
                </thead>
                <tbody>
                    {rows.map((e, i) =>
                        <TableRow key={ i } style={{ background: i % 2 !== 0 ? colors.white : '#d7d7d7' }}>
                            { e.c1 ? <TableEntry dangerouslySetInnerHTML={{ __html: e.c1 }} /> : '' }
                            { e.c2 ? <TableEntry dangerouslySetInnerHTML={{ __html: e.c2 }} /> : '' }
                            { e.c3 ? <TableEntry dangerouslySetInnerHTML={{ __html: e.c3 }} /> : '' }
                            { e.c4 ? <TableEntry dangerouslySetInnerHTML={{ __html: e.c4 }} /> : '' }
                            { e.c5 ? <TableEntry dangerouslySetInnerHTML={{ __html: e.c5 }} /> : '' }
                            { e.c6 ? <TableEntry dangerouslySetInnerHTML={{ __html: e.c6 }} /> : '' }
                        </TableRow>
                    )}
                </tbody>
            </SimpleTable>
        )
    }
}

const TableHeadItem = styled.th`
    text-align: left;
    padding-bottom: 15px;
    padding-right: 20px;

    &:first-child {
        padding-left: 20px;
    }
`

const TableRow = styled.tr`

`

const TableEntry = styled.td`
    padding: 10px;
    padding-left: 0;

    &:first-child {
        padding-left: 20px;
    }
`

const SimpleTable = styled.table`
    border-spacing: 0;
`

export { Table }
