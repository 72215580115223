import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
    UpperHeader,
    H3Header,
    Italic,
    ImageCard,
    UnstyledLink,
    Block,
    Grid,
    ItalicLink,
    Section,
    Header,
    devices,
} from '../../Common'

const SpotlightItem = ({ block, imageHeight, textPadding, LinkComp, ie, id }) => (
    <FlexColumn
        margintop={ 30 }
        ie={ ie ? 1 : 0 }
        marginright={ id === 3 ? 0 : 15 }
    >
        <ImageCard
            background={ block.image }
            height={ imageHeight }
            href={ block.external_url }
            target={block.external_url ? '_blank' : ''}
            to={ block.linked_page }
            as={ block.linked_page ? Link : 'a' }
            title={ block.heading }
        />
        <TextContent paddingRight={ textPadding }>
            <UpperHeader>{ block.heading }</UpperHeader>
            <H3Header>
                <UnstyledLink href={ block.external_link } to={ block.linked_page } as={ block.linked_page ? Link : 'a' }>
                    <Block paddingbottom={ 20 }>{ block.description }</Block>
                </UnstyledLink>
            </H3Header>
            <div className='before-top' />
            <Italic marginbottom={ 40 }>
                <LinkComp href={ block.explore_more_link } to={ block.explore_more_page } as={ block.explore_more_page ? Link : 'a' }>
                    { block.explore_more_text }
                </LinkComp>
            </Italic>
        </TextContent>
    </FlexColumn>
)


class Spotlight extends Component {
    render() {
        const { spotlight } = this.props.page
        const { ie } = this.props
        let { boilerplate } = this.props
        boilerplate = boilerplate.BLOCKS || boilerplate

        return (
            <Section marginbottom={ ie ? 140 : '' }>
                <Block>
                    <Header fontSize={ 30 }>
                        {/* BOILERPLATE BLOCKS SPOTLIGHT 'HMH Spotlights' */}
                        { boilerplate.SPOTLIGHT }
                    </Header>
                    <Grid columns={ window.innerWidth < 800 ? 2 : 4 } totalmargin={ window.innerWidth < 800 ? 30 : 15 * 3 }>
                        { spotlight.map((block, id) =>
                            <SpotlightItem
                                ie={ ie }
                                key={ `#${id}` }
                                block={ block }
                                imageHeight={ 381 }
                                LinkComp={ ItalicLink }
                                id={ id }
                            />
                        ) }
                    </Grid>
                </Block>
            </Section>
        )
    }
}

const FlexColumn = styled(Grid.Column)`
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    margin-right: ${props => props.marginright}px;
    @media ${devices.mobile} {
        margin-right: 0;
    }
`

const TextContent = styled.div`
    padding-top: 1rem;
    padding-right: ${({ paddingRight='2rem' }) => paddingRight};
    ${props => props.ie ? '' : 'flex: 1;'}
    display: flex;
    flex-direction: column;
    .before-top {
        margin-bottom: auto;
    }
`

function mapStateToProps(state) {
    return {
        page: state.content.page,
        ie: state.content.ie,
        boilerplate: state.content.boilerplate
    }
}

const connectedSpotlight = connect(mapStateToProps)(Spotlight)
export { connectedSpotlight as Spotlight }
