import { formTypes } from '../_constants'

const initialState = {
    groupForm: { states: [], grades: [] },
    volunteerForm: { languages: [] },
    jobApplicationForm: { positions: [] },
    message: '',
    error: '',
}

export function form(state = initialState, action) {
    switch (action.type) {
        case formTypes.GET_GROUP_FORM_SUCCESS:
            return {
                ...state,
                groupForm: { ...action.payload },
            }
        case formTypes.POST_GROUP_FORM_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                error: '',
            }
        case formTypes.POST_GROUP_FORM_FAILURE:
            return {
                ...state,
                error: action.payload,
                message: '',
            }
        case formTypes.GET_VOLUNTEER_FORM_SUCCESS:
            return {
                ...state,
                volunteerForm: { ...action.payload },
            }
        case formTypes.POST_VOLUNTEER_FORM_SUCCESS:
            return {
                ...state,
                message: action.payload.message,
                error: '',
            }
        case formTypes.POST_VOLUNTEER_FORM_FAILURE:
            return {
                ...state,
                error: action.payload,
                message: '',
            }
        case formTypes.GET_JOB_APPLICATION_FORM_SUCCESS:
            return {
                ...state,
                jobApplicationForm: { ...action.payload },
            }
        case formTypes.POST_JOB_APPLICATION_FORM_SUCCESS:
            return {
                ...state,
                message: action.message,
                error: '',
            }
        case formTypes.POST_JOB_APPLICATION_FORM_FAILURE:
            return {
                ...state,
                error: action.error,
                message: '',
            }
        case formTypes.RESET:
            return {
                ...state,
                error: '',
                message: '',
            }
        default:
            return state
    }
}
