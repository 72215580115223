import React from 'react'
import styled from 'styled-components'
import { devices, margins } from './Common'
import colors from '../../css/colors.scss'


const Header = ({ as='div', ...rest }) => {
    return React.createElement(styled(as).attrs(() => rest)`
        font-family: neue-haas-grotesk-display, sans-serif;
        font-weight: 700;
        max-width: ${props => props.unsetmaxwidth ? 'unset' : '690px'};
        line-height: 1.2;
        font-size: ${({ fontSize=44 }) => fontSize}px;
        ${margins}
        @media ${devices.mobile} {
           font-size: 25px;
        }
    `)
}

const UpperHeader = styled.h2`
    font-size: ${({ fontSize=16 }) => fontSize}px;
    color: ${({ color='inherit' }) => color};
    font-weight: 200;
    letter-spacing: 2px;
    font-family: 'Financier';
    text-transform: uppercase;
    ${margins}
    @media ${devices.mobile} {
       font-size: 16px;
    }
`

const H3Header = styled(Header).attrs({ as: 'h3' })`
    color: ${({ color=colors.darkgray }) => color};
    font-size: ${({ fontSize=25 }) => fontSize}px;
    margin-top: ${({ margintop=10 }) => margintop}px;
    margin-bottom: ${({ marginbottom=5 }) => marginbottom}px;
    line-height: 1.05;
    @media ${devices.mobile} {
       font-size: 22px;
    }
`

const Italic = styled(Header)`
    && {
        font-family: 'Financier';
        font-size: ${({ fontSize=20 }) => fontSize}px;
        font-style: ${({ regular }) => regular ? 'normal': 'italic'};
        color: ${({ color='inherit' }) => color};
        font-weight: 500;
        line-height: 1.1;
        @media ${devices.mobile} {
           font-size: 18px;
           padding-top: 7px;
        }
    }
`

const UnstyledLink = ({ as='a', ...rest }) => {
    const component = styled(as).attrs(() => rest)`
        cursor: pointer;
        color: inherit;
        text-decoration: none;
        &:hover, &:focus, &:active, &:visited {
            color: inherit;
        }
    `

    return React.createElement(component, rest)
}

const ItalicLink = styled(Italic.withComponent('a'))`
    cursor: pointer;
    text-decoration: none;
    &:hover, &:focus, &:active {
        color: inherit !important;
    }
`

export const Em = styled.span`
    font-weight: 700;
`


export {
    UpperHeader,
    H3Header,
    Italic,
    ItalicLink,
    Header,
    UnstyledLink,
    margins,
}
