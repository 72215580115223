import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { UnstyledLink } from '../../Common'
import colors from '../../../css/colors.scss'

class Info extends Component {
    state = {
        info: this.props.subBlocks || [],
        intro: this.props.intro,
    }

    static getDerivedStateFromProps(props, state) {
        if (props.subBlocks && props.subBlocks !== state.subBlocks) state.subBlocks = props.subBlocks
        if (props.intro && props.intro !== state.intro) state.intro = props.intro
        return state
    }

    render() {
        return (
            <InfoBlock>
                { this.state.intro ? <Intro dangerouslySetInnerHTML={{ __html: this.state.intro }} /> : '' }
                {this.state.info.map(e => (
                    <MenuLink key={ e.title }>
                        <UnstyledLink
                            as={ e.external_url ? 'a' : Link }
                            href={ e.external_url ? e.external_url : undefined }
                            target={ e.external_url ? '_blank'  : undefined }
                            rel={ e.external_url ? 'noopener noreferrer' : undefined }
                            to={ e.external_url ? undefined : e.page }
                        >
                            { e.title }
                        </UnstyledLink>
                    </MenuLink>
                ))}
            </InfoBlock>
        )
    }
}

const InfoBlock = styled.div`
    right: 0;
`

const Intro = styled.div`
    margin-bottom: 20px;
`

const MenuLink = styled.div`
    margin-bottom: 10px;
    text-decoration: none;
    color: ${colors.darkgray};

    &:last-of-type {
        margin-bottom: 0;
    }
`

export { Info }
