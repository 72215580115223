import { eventTypes } from '../_constants'


const initialState = {
    list: [],
    page: {}
}

export function event(state = initialState, action) {
    switch (action.type) {
        case eventTypes.EVENT_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload
            }
        case eventTypes.CLEAR_EVENT_LIST:
            return {
                ...state,
                list: []
            }
        case eventTypes.EVENT_PAGE_SUCCESS:
            return {
                ...state,
                page: action.payload
            }
        case eventTypes.CLEAR_EVENT:
            return {
                ...state,
                page: {}
            }
        default:
            return state
    }
}
