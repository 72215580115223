import React from 'react'

const TextField = ({ input, label, type, placeholder, required, meta: { touched, error } }) => (
    <div className='input-wrap'>
        <label>{ label }{ required ? '*' : '' } { touched && error && <span>{ error }</span> }</label>
        <div className='input-container'>
            <input { ...input } placeholder={ placeholder } type={ type } />
        </div>
    </div>
)

export { TextField }
