import React, { Suspense } from 'react'

import { render } from 'react-dom'
import { Provider } from 'react-redux'

import { store } from './_helpers'
import { App } from './App'

render(
    <Provider store={ store }>
        <Suspense fallback={<span />}>
            <App />
        </Suspense>
    </Provider>,
    document.getElementById('root')
)
