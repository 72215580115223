import React from 'react'

const SelectField = ({ input, label, type, placeholder, required, options, meta: { touched, error } }) => (
    <div className='input-wrap'>
        <label>{ label }{ required ? '*' : '' } { touched && error && <span>{ error }</span> }</label>
        <div className='input-container'>
            <div className='select-wrapper'>
                <select { ...input } placeholder={ placeholder } type={ type }>
                    <option value=''>---------</option>
                    { options.map(e => <option key={ e[0] } value={ e[0] }>{ e[1] }</option>) }
                </select>
            </div>
        </div>
    </div>
)

export { SelectField }
