import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
// import GoogleMaps from 'simple-react-google-maps'
import {
    Gradient,
    Container,
    Grid,
    Image,
    Header,
    Input,
    Button,
    Form,
    UnstyledLink,
    devices,
    minDevices,
} from '../Common'
import { ScrollTopButton } from './ScrollTopButton'
import { loadGlobalHours, subscribe, loadFooterLinks, loadFooterContent } from '../../_actions'

// const MAPS_API_KEY = 'AIzaSyDZpCfFClZ7vNqhR0x_Z54gpLzKpwX3UBQ'

class Footer extends Component {
    state = {
        showMap: false,
        email: '',
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(loadGlobalHours())
        dispatch(loadFooterLinks())
        dispatch(loadFooterContent())
    }

    handleShowMap = (e) => {
        e.preventDefault()
        this.setState({ showMap: true })
    }

    subscribe = (e) => {
        e.preventDefault()
        if (this.state.email && this.state.email.match('@')) {
            this.props.dispatch(subscribe(this.state.email))
        }
    }

    updateField = (e) => {
        const name = e.target.name
        const value = e.target.value

        this.setState({ [name]: value })
    }

    render() {
        const { globalHours, newsletterError, newsletterMessage, footerLinks, footerContent } = this.props
        const spanish = document.documentElement.lang === 'es'
        let { boilerplate } = this.props
        boilerplate = boilerplate.NAV || boilerplate

        return (
            <FooterWrap>
                <FooterContainer>
                    <Top />
                    <FooterGrid margintop={ 60 }>
                        <MapWrap width={ 25 } tabletWidth={ 100 } open={ this.state.showMap } margintop={ 30 }>
                            <iframe width='350'
                                    height='215'
                                    id='gmap_canvas'
                                    src='https://maps.google.com/maps?q=holocaust%20museum%20houston&t=&z=15&ie=UTF8&iwloc=&output=embed'
                                    frameBorder='0'
                                    scrolling='no'
                                    marginHeight='0'
                                    marginWidth='0'
                                    title='Map showing where the Holocaust Musuem Houston is located'
                            />
                        </MapWrap>
                        <Grid.Column width={ 25 } tabletWidth={ 50 } margintop={ 30 }>
                            <CompanyInfo>
                                <SocialWrap>
                                    <SocialLink>
                                        <a href='https://twitter.com/HMHou?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor' target='_blank' rel='noopener noreferrer'>
                                            <Image src='/static/images/twitter-2.svg' aria-label={ `${boilerplate.HMH} Twitter` } height={ 18 } />
                                        </a>
                                    </SocialLink>
                                    <SocialLink>
                                        <a href='https://www.facebook.com/HOLOCAUST-MUSEUM-HOUSTON-78039800708/' target='_blank' rel='noopener noreferrer'>
                                            <Image src='/static/images/facebook-2.svg' aria-label={ `${boilerplate.HMH} Facebook` } height={ 18 } />
                                        </a>
                                    </SocialLink>
                                    <SocialLink>
                                        <a href='https://www.instagram.com/hmhou/?hl=en' target='_blank' rel='noopener noreferrer'>
                                            <Image src='/static/images/instagram-2.svg' aria-label={ `${boilerplate.HMH} Instagram` } height={ 18 } />
                                        </a>
                                    </SocialLink>
                                </SocialWrap>
                                <FooterFirstHeader>
                                    <Header as='h4'>
                                        {/* BOILERPLATE NAV HOURS 'HOURS OF OPERATION' */}
                                        { boilerplate.HOURS }
                                    </Header>
                                </FooterFirstHeader>
                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: globalHours.footer_hours_text }} />
                                    {/* BOILERPLATE NAV HOLIDAY 'Holiday Schedule' */}
                                    {/*
                                    <Link to='#'>{ boilerplate.HOLIDAY }</Link>
                                    */}
                                </div>
                                <FooterSecondHeader>
                                    <Header as='h4'>
                                        {/* BOILERPLATE NAV HMH 'Holocaust Museum Houston' */}
                                        { boilerplate.HMH }
                                    </Header>
                                </FooterSecondHeader>
                                <div>
                                    {/* BOILERPLATE NAV MAP 'MAP' */}
                                    Lester and Sue Smith Campus
                                    <br />
                                    5401 Caroline
                                    <br/>
                                    Houston, TX 77004 <MapLink as={ Link } to='#' onClick={ this.handleShowMap }>{ boilerplate.MAP }</MapLink>
                                    <br/>
                                    <a href='tel:7139428000'>(713) 942-8000</a>
                                    <br />
                                    {/* BOILERPLATE NAV FAX 'Fax' */}
                                    { boilerplate.FAX }: (713) 527-1652
                                    <br/>
                                    <a href='mailto:info@hmh.org'>info@hmh.org</a>
                                </div>
                            </CompanyInfo>
                        </Grid.Column>
                        <ExtraFooterContent width={ 25 } tabletWidth={ 50 } margintop={ 30 }>
                            <ExtraContent footerContent={ footerContent } />
                        </ExtraFooterContent>
                        <FooterMenu width={ 17 } tabletWidth={ 50 } margintop={ 30 }>
                            <MailingListContainer>
                                {/* BOILERPLATE NAV MAILING_LIST 'Join Mailing List' */}
                                <JoinMailingForm
                                    subscribe={ this.subscribe }
                                    email={ this.state.email }
                                    updateField={ this.updateField }
                                    paddingbottom={ newsletterError || newsletterMessage ? 5 : 25 }
                                    boilerplate={ boilerplate }
                                />
                                {newsletterError || newsletterMessage ? (
                                    <MailingListMessage error={ newsletterError ? 1 : 0 }>
                                        {/* BOILERPLATE NAV NEWSLETTER_SUCCESS 'You are now subscribed to the Holocaust Museum Houston mailing list.' */}
                                        {/* BOILERPLATE NAV NEWSLETTER_FAIL 'We were not able to subscribe you to the Holocaust Museum Houston newsletter, please try again later.' */}
                                        { boilerplate.NEWSLETTER_SUCCESS || boilerplate.NEWSLETTER_FAIL }
                                    </MailingListMessage>
                                ) : ''}
                            </MailingListContainer>
                            {footerLinks && footerLinks.map(e => (
                                <FooterLinkItem key={ e.link_title }>
                                    <Header>
                                        <UnstyledLink as={ Link } to={ e.linked_page }>
                                            { e.link_title }
                                        </UnstyledLink>
                                    </Header>
                                </FooterLinkItem>
                            ))}
                        </FooterMenu>
                        <ExtraFooterContentMobile width={ 50 } tabletWidth={ 100 }>
                            <ExtraContent footerContent={ footerContent } />
                        </ExtraFooterContentMobile>
                    </FooterGrid>
                    <FooterGrid>
                        {/* BOILERPLATE NAV HMH 'Holocaust Museum Houston' */}
                        {/* BOILERPLATE NAV PRIVACY 'Privacy Policy' */}
                        <CopyrightWrap>&#169;</CopyrightWrap>&nbsp;{ boilerplate.HMH }&nbsp;|&nbsp;<Link to={ spanish ? '/visite/pautas-para-visitas-y-fotografias/' : '/visit/tour-and-photo-guidelines/' }>{ boilerplate.PRIVACY }</Link>
                    </FooterGrid>
                </FooterContainer>
                <Bottom />
                <ScrollTopButton />
            </FooterWrap>
        )
    }
}

const JoinMailingForm = ({ subscribe, email, updateField, paddingbottom, boilerplate }) => (
    <MailingForm onSubmit={ subscribe } paddingbottom={ paddingbottom }>
        <Input placeholder={ boilerplate.MAILING_LIST } value={ email } name='email' onChange={ updateField } aria-label='Newsletter email'/>
        <Checkmark basic role='button' aria-label='Subscribe to newsletter'>
            <div/>
        </Checkmark>
    </MailingForm>
)

const ExtraContent = ({ footerContent }) => {
    let flex, maxWidth
    if (footerContent.image) {
        if (footerContent.image2) {
            if (footerContent.image3) {
                flex = 0.3
                maxWidth = 31
            } else {
                flex = 0.5
                maxWidth = 48
            }
        } else {
            flex = 1
            maxWidth = 100
        }
    }

    return (
        <React.Fragment>
            <ExtraText dangerouslySetInnerHTML={{ __html: footerContent.text }} />
            <ImageContainer>
                <FooterImage src={ footerContent.image } flex={ flex } maxWidth={ maxWidth } />
                <FooterImage src={ footerContent.image2 } flex={ flex } maxWidth={ maxWidth } />
                <FooterImage src={ footerContent.image3 } flex={ flex } maxWidth={ maxWidth } />
            </ImageContainer>
        </React.Fragment>
    )
}

const MailingListContainer = styled.div`

`

const MailingListMessage = styled.div`
    margin-bottom: 25px;
    color: ${props => props.error ? 'red' : '#00c136'}
`

const Checkmark = styled(Button)`
    && {
        width: 35px;
        padding: 7px 10px;
        vertical-align: bottom;
        div {
            background: center / contain no-repeat url('/static/images/checkmark.svg');
            height: 14px;
            width: 14px;
        }
    }
`

const ExtraFooterContent = styled(Grid.Column)`
    display: inline-block;
    @media ${devices.desktop} {
        display: none;
    }
`

const ExtraFooterContentMobile = styled(Grid.Column)`
    display: none;
    @media ${devices.desktop} {
        display: inline-block;
        order: 3;
        margin-bottom: 30px;
    }
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
`

const FooterImage = styled(Image)`
    max-width: 31%;
    flex: 0.3 0 0;
`

const ExtraText = styled.div`
    margin-bottom: 30px;

    @media ${devices.tablet} and ${minDevices.mobile} {
        margin-top: 30px;
    }
`

const MailingForm = styled(Form)`
    padding-bottom: ${props => props.paddingbottom}px;
    display: flex;
    div {
        flex: 1;
    }
    input {
        border-right: none !Important;
    }
`

const SocialWrap = styled.div`
    margin-bottom 15px;
    height: 18px;
`

const FooterContainer = styled(Container)`
    margin-bottom: 25px;
    padding-bottom: 0!important;
`

const FooterWrap = styled.footer`
    width:100%;
    * {
        font-size: 12px !important;
        line-height: 16px;
    }
`

const Bottom = styled(Gradient)`
    width: 100%;
    height: 16px;
`

const Top = styled(Gradient)`
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
`

const SocialLink = styled.div`
    float: left;
    margin-right: 15px;
`

const FooterLinkItem = styled.div`
    padding-bottom: 10px;
    @media ${devices.mobile} {
        padding-bottom: 5px;
    }
`

const FooterFirstHeader = styled.div`
    padding-bottom: 10px;
`

const FooterSecondHeader = styled.div`
    padding-top: 35px;
    @media ${devices.mobile} {
        padding-top: 20px;
    }
    padding-bottom: 8px;
`

const CompanyInfo = styled.div`
    padding-left: 30px;
    @media ${devices.tablet} {
        padding-left: 0;
    }
`

const FooterMenu = styled(Grid.Column)`
    margin-left: auto;
    @media ${devices.mobile} {
        margin-left: 0;
        margin-bottom: 20px;
        order: 2;
    }
`

const MapWrap = styled(Grid.Column)`
    @media ${devices.mobile} {
        order: 1;
        display: ${({ open }) => open ? 'block' : 'none'};
    }
    @media ${minDevices.mobile} {
        min-width: 350px;
    }
`

const MapLink = styled.a`
    @media ${minDevices.mobile} {
        display: none;
    }
`

const FooterGrid = styled(Grid)`
    margin-top: ${({ margintop }) => margintop}px;
    @media ${devices.mobile} {
        margin-top: 0;
        position: relative;
        ${SocialWrap} {
            display: flex;
            flex-direction: row;
            ${Image} {
                height: 26px;
                margin-bottom: 23px;
            }
        }
    }
`

const CopyrightWrap = styled.span`
    @media ${devices.mobile} {
        margin-left: 0;
    }
`

// const StaticMap = styled.div`
//     background: center / cover no-repeat url(https://maps.googleapis.com/maps/api/staticmap?center=29.725052,-95.385626&zoom=16&scale=2&size=600x600&maptype=roadmap&key=${MAPS_API_KEY}&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0x474747000000%7Clabel:%7C29.725052,-95.385626);
//     height: 215px;
// `

function mapStateToProps(state) {
    return {
        globalHours: state.content.globalHours,
        footerLinks: state.content.footerLinks,
        footerContent: state.content.footerContent,
        newsletterMessage: state.content.newsletterMessage,
        newsletterError: state.content.newsletterError,
        boilerplate: state.content.boilerplate,
    }
}
const connectedFooter = connect(mapStateToProps)(Footer)
export { connectedFooter as Footer }
