import axios from 'axios'
import { cookies } from '../_helpers'

export const Form = {
    Group: {
        get: () => axios.get(`/forms/group-tour/`),
        post: data => axios.post(`/forms/group-tour/`, { ...data }, { headers: { 'X-CSRFToken': cookies.getCookie('csrftoken') }}),
    },
    Volunteer: {
        get: () => axios.get(`/forms/volunteer/`),
        post: data => axios.post(`/forms/volunteer/`, { ...data }, { headers: { 'X-CSRFToken': cookies.getCookie('csrftoken') }}),
    },
    JobApplication: {
        get: () => axios.get(`/forms/job-application/`),
        post: data => {
            return fetch('/forms/job-application/', {
                method: 'POST',
                body: data,
                headers: {
                    'X-CSRFToken': cookies.getCookie('csrftoken'),
                }
            }).then(res => res.json())
        }
    }
}
