import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import {
    CheckBoxField,
    DateField,
    Error,
    RadioGroup,
    SelectField,
    TextAreaField,
    TextField,
    TimeField,
} from './Inputs'
import { postGroupForm } from '../../_actions'
import { UpperHeader } from '../Common'

class GroupTourForm extends Component {
    state = {
        showCollege: false,
        showAdultGroup: false,
        grades: this.props.grades || [],
        states: this.props.states || [],
        tourLengths: this.props.tourLengths || [],
        boilerplate: this.props.boilerplate || {},
    }

    static getDerivedStateFromProps(props, state) {
        if (props.boilerplate && props.boilerplate !== state.boilerplate) state.boilerplate = props.boilerplate
        if (props.grades && props.grades !== state.grades) state.grades = props.grades
        if (props.states && props.states !== state.states) state.states = props.states
        if (props.tourLengths && props.tourLengths !== state.tourLengths) state.tourLengths = props.tourLengths
        return state
    }

    toggleCollege = show => this.setState({ showCollege: show })

    toggleAdultGroup = show => this.setState({ showAdultGroup: show })

    required = value => {
        {/* BOILERPLATE FORM GROUP_VALIDATION_REQUIRED 'Required' */}
        return (value ? undefined : this.state.boilerplate.GROUP_VALIDATION_REQUIRED)
    }

    number = value => {
        {/* BOILERPLATE FORM GROUP_VALIDATION_NUMBER 'Must be a number' */}
        return value && isNaN(Number(value)) ? this.state.boilerplate.GROUP_VALIDATION_NUMBER : undefined
    }

    positiveNumber = value => {
        {/* BOILERPLATE FORM GROUP_VALIDATION_POSITIVE 'Must be a positive number' */}
        return value && value >= 0 ? undefined : this.state.boilerplate.GROUP_VALIDATION_POSITIVE
    }

    email = value => {
        {/* BOILERPLATE FORM GROUP_VALIDATION_EMAIL 'Invalid email address' */}
        return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            ? this.state.boilerplate.GROUP_VALIDATION_EMAIL
            : undefined
    }

    phoneNumber = value => {
        {/* BOILERPLATE FORM GROUP_VALIDATION_PHONE 'Invalid phone number, it must be 10 digits' */}
        return value && !/^(0|[1-9][0-9]{9})$/i.test(value)
            ? this.state.boilerplate.GROUP_VALIDATION_PHONE
            : undefined
    }

    zipcode = value => {
        {/* BOILERPLATE FORM GROUP_VALIDATION_ZIPCODE 'Invalid zipcode, it must be 5 digits' */}
        return value && !isNaN(Number(value)) && String(value).length === 5
            ? undefined
            : this.state.boilerplate.GROUP_VALIDATION_ZIPCODE
    }

    validate = values => {
        const errors = {}

        errors.name = this.required(values.name)
        errors.city = this.required(values.city)
        errors.state = this.required(values.state)
        errors.zipcode = this.zipcode(values.zipcode)
        errors.phone_number = this.phoneNumber(values.phone_number)
        errors.title_1 = this.required(values.title_1)
        errors.primary_contact_first_name = this.required(values.primary_contact_first_name)
        errors.primary_contact_last_name = this.required(values.primary_contact_last_name)
        errors.primary_contact_email = this.email(values.primary_contact_email)
        errors.primary_contact_phone_number = this.phoneNumber(values.primary_contact_phone_number)
        errors.tour_date_first_choice = this.required(values.tour_date_first_choice)
        errors.tour_date_second_choice = this.required(values.tour_date_second_choice)
        errors.tour_time_first_choice = this.required(values.tour_time_first_choice)
        errors.tour_time_second_choice = this.required(values.tour_time_second_choice)
        errors.number_of_students = this.positiveNumber(values.number_of_students)
        errors.number_of_adults = this.positiveNumber(values.number_of_adults)
        errors.total_number_of_participants = this.positiveNumber(values.total_number_of_participants)
        errors.visit_length = this.required(values.visit_length)
        errors.spanish_docent = this.required(values.spanish_docent)

        // check last required field for touched before displaying error
        if (values.spanish_docent && !values.holocaust && !values.human_rights_gallery && !values.bak_art_gallery && !values.and_still_i_write_gallery && !values.voices_video && !values.temporary_exhibition) {
            errors.galery_error = 'SELECT_A_GALERY'
        } else {
            delete errors.galery_error
        }
        return errors
    }

    submit = (data) => {
        const { dispatch } = this.props
        const errors = this.validate(data)
        for (const key in errors) {
            {/* BOILERPLATE FORM GROUP_VALIDATION_ERROR 'Please fill in all required fields.' */}
            const err = errors[key]
            if (err) {
                throw new SubmissionError({
                    [key]: errors[key],
                    _error: key === 'galery_error'
                        ? this.state.boilerplate.SELECT_A_GALERY
                        : this.state.boilerplate.GROUP_VALIDATION_ERROR
                })
            }
        }
        dispatch(postGroupForm(data))
    }

    render() {
        const { handleSubmit, error, formData, submitFailed, submitting } = this.props
        let minDate = new Date()
        const blackout = new Date('March 1, 2020')
        const formErrors = submitFailed && formData && formData.syncErrors ? !!Object.keys(formData.syncErrors).length : false

        if (minDate < blackout) {
            minDate = blackout
        }
        return (
            <form onSubmit={ handleSubmit(this.submit) }>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM GROUP_HEADER_INFORMATION 'Group Information' */}
                        { this.state.boilerplate.GROUP_HEADER_INFORMATION }
                    </UpperHeader>
                    {/* BOILERPLATE FORM GROUP_NAME 'Name of School or Group' */}
                    { this.props.name }
                    <Field
                        name='name'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.GROUP_NAME }
                        required
                        validate={ [this.required] } />
                    <div className='input-group'>
                        {/* BOILERPLATE FORM GROUP_CITY 'City' */}
                        <Field
                            name='city'
                            type='text'
                            component={ TextField }
                            label={ this.state.boilerplate.GROUP_CITY }
                            required
                            validate={ [this.required] } />
                        {/* BOILERPLATE FORM GROUP_STATE 'State' */}
                        <Field
                            name='state'
                            options={ this.state.states }
                            component={ SelectField }
                            label={ this.state.boilerplate.GROUP_STATE }
                            required
                            validate={ [this.required] } />
                    </div>
                    {/* BOILERPLATE FORM GROUP_ZIPCODE 'Zip Code' */}
                    <Field
                        name='zipcode'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.GROUP_ZIPCODE }
                        required
                        validate={ [this.required, this.zipcode] } />
                    {/* BOILERPLATE FORM GROUP_PHONE_NUMBER 'Phone Number' */}
                    <Field
                        name='phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.GROUP_PHONE_NUMBER }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                    {/* BOILERPLATE FORM GROUP_SCHOOL_DISTRICT 'School District' */}
                    <Field
                        name='school_district'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.GROUP_SCHOOL_DISTRICT } />
                    {/* BOILERPLATE FORM TITLE_1 'Title 1' */}
                    {/* BOILERPLATE FORM YES 'Yes' */}
                    {/* BOILERPLATE FORM NO 'No' */}
                    <Field
                        name='title_1'
                        label={ this.state.boilerplate.TITLE_1 }
                        component={ RadioGroup }
                        required
                        validate={ [this.required] }
                        options={[
                            { title: this.state.boilerplate.YES, value: 'Yes' },
                            { title: this.state.boilerplate.NO, value: 'No' },
                        ]} />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM GROUP_HEADER_CONTACT 'Primary Contact Information' */}
                        { this.state.boilerplate.GROUP_HEADER_CONTACT }
                    </UpperHeader>
                    <div className='input-group'>
                        {/* BOILERPLATE FORM GROUP_FIRST_NAME 'First Name' */}
                        <Field
                            name='primary_contact_first_name'
                            type='text'
                            component={ TextField }
                            label={ this.state.boilerplate.GROUP_FIRST_NAME }
                            required
                            validate={ [this.required] } />
                        {/* BOILERPLATE FORM GROUP_LAST_NAME 'Last Name' */}
                        <Field
                            name='primary_contact_last_name'
                            type='text'
                            component={ TextField }
                            label={ this.state.boilerplate.GROUP_LAST_NAME }
                            required
                            validate={ [this.required] } />
                    </div>
                    {/* BOILERPLATE FORM GROUP_CONTACT_EMAIL 'Email' */}
                    <Field
                        name='primary_contact_email'
                        type='email'
                        component={ TextField }
                        label={ this.state.boilerplate.GROUP_CONTACT_EMAIL }
                        required
                        validate={ [this.required, this.email] } />
                    {/* BOILERPLATE FORM GROUP_CONTACT_PHONE 'Cell Phone Number' */}
                    <Field
                        name='primary_contact_phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.GROUP_CONTACT_PHONE }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM GROUP_HEADER_SCHEDULE 'Scheduling Information' */}
                        { this.state.boilerplate.GROUP_HEADER_SCHEDULE }
                    </UpperHeader>
                    <UpperHeader marginbottom={ 5 } className='secondary-label'>
                        {/* BOILERPLATE FORM GROUP_HEADER_DATE 'Tour Date' */}
                        { this.state.boilerplate.GROUP_HEADER_DATE }
                    </UpperHeader>
                    <div className='input-group'>
                        {/* BOILERPLATE FORM GROUP_FIRST 'First Choice' */}
                        <Field
                            name='tour_date_first_choice'
                            component={ DateField }
                            label={ this.state.boilerplate.GROUP_FIRST }
                            min={ minDate }
                            required
                            validate={ [this.required] } />
                        {/* BOILERPLATE FORM GROUP_SECOND 'Second Choice' */}
                        <Field
                            name='tour_date_second_choice'
                            component={ DateField }
                            label={ this.state.boilerplate.GROUP_SECOND }
                            min={ minDate }
                            required
                            validate={ [this.required] } />
                    </div>
                    <UpperHeader marginbottom={ 5 } className='secondary-label'>
                        {/* BOILERPLATE FORM GROUP_HEADER_TIME 'Tour Time' */}
                        { this.state.boilerplate.GROUP_HEADER_TIME }
                    </UpperHeader>
                    <div className='input-group'>
                        {/* BOILERPLATE FORM GROUP_FIRST 'First Choice' */}
                        <Field
                            name='tour_time_first_choice'
                            component={ TimeField }
                            label={ this.state.boilerplate.GROUP_FIRST }
                            required
                            validate={ [this.required] } />
                        {/* BOILERPLATE FORM GROUP_SECOND 'Second Choice' */}
                        <Field
                            name='tour_time_second_choice'
                            component={ TimeField }
                            label={ this.state.boilerplate.GROUP_SECOND }
                            required
                            validate={ [this.required] } />
                    </div>
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM GROUP_HEADER_PARTICIPANTS 'Number of Participants' */}
                        { this.state.boilerplate.GROUP_HEADER_PARTICIPANTS }
                    </UpperHeader>
                    {/* BOILERPLATE FORM GROUP_NUMBER_STUDENTS 'Number of Students' */}
                    <Field
                        name='number_of_students'
                        type='number'
                        component={ TextField }
                        label={ this.state.boilerplate.GROUP_NUMBER_STUDENTS }
                        required
                        validate={ [this.required, this.number, this.positiveNumber] } />
                    {/* BOILERPLATE FORM GROUP_NUMBER_ADULTS 'Number of Adults' */}
                    <Field
                        name='number_of_adults'
                        type='number'
                        component={ TextField }
                        label={ this.state.boilerplate.GROUP_NUMBER_ADULTS }
                        required
                        validate={ [this.required, this.number, this.positiveNumber] } />
                    {/* BOILERPLATE FORM GROUP_TOTAL_NUMBER 'Total Number of Participants' */}
                    <Field
                        name='total_number_of_participants'
                        type='number'
                        component={ TextField }
                        label={ this.state.boilerplate.GROUP_TOTAL_NUMBER }
                        required
                        validate={ [this.required, this.number, this.positiveNumber] } />
                    <div className='form-note'>
                        {/* BOILERPLATE FORM GROUP_AMOUNT_NOTE 'Please note that the museum requires one adult for every fifteen students.' */}
                        { this.state.boilerplate.GROUP_AMOUNT_NOTE }
                    </div>
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM GROUP_HEADER_TOUR 'Tour Information' */}
                        { this.state.boilerplate.GROUP_HEADER_TOUR }
                    </UpperHeader>
                    {/* BOILERPLATE FORM GROUP_VISIT_LENGTH 'How long is your visit:' */}
                    <Field
                        name='visit_length'
                        options={ this.state.tourLengths }
                        component={ SelectField }
                        required
                        validate={ [this.required] }
                        label={ this.state.boilerplate.GROUP_VISIT_LENGTH } />
                    <UpperHeader marginbottom={ 15 } margintop={ 20 } className='secondary-label'>
                        {/* BOILERPLATE FORM GROUP_SELECT_EXHIBITIONS 'Please choose what galleries your group wants to visit*' */}
                        { this.state.boilerplate.GROUP_SELECT_EXHIBITIONS }
                    </UpperHeader>
                    {/* BOILERPLATE FORM GROUP_HOLOCAUST 'Holocaust (estimated tour time is 1 hour)' */}
                    {/* BOILERPLATE FORM SELECT_A_GALERY 'Please select at least on gallery' */}
                    <Field name='galery_error' component={ Error } errorText={ this.state.boilerplate.SELECT_A_GALERY } />
                    <Field
                        name='holocaust'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.GROUP_HOLOCAUST } />
                    {/* BOILERPLATE FORM GROUP_HUMAN_RIGHTS 'Human Rights Gallery (estimated tour time 30 minutes)' */}
                    <Field
                        name='human_rights_gallery'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.GROUP_HUMAN_RIGHTS } />
                    {/* BOILERPLATE FORM GROUP_BAK 'Bak Art Gallery (estimated tour time is 30 minutes)' */}
                    <Field
                        name='bak_art_gallery'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.GROUP_BAK } />
                    {/* BOILERPLATE FORM GROUP_AND_STILL_I_WRITE 'And Still I Write: Young Diarist on War and Genocide Gallery (estimated tour time is 30 minutes)' */}
                    <Field
                        name='and_still_i_write_gallery'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.GROUP_AND_STILL_I_WRITE } />
                    {/* BOILERPLATE FORM GROUP_VOICES_VIDEO 'Voices Video: Testimony of survivors who settled in Houston (estimated tour time is 30 minutes)' */}
                    <Field
                        name='voices_video'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.GROUP_VOICES_VIDEO } />
                    {/* BOILERPLATE FORM GROUP_TEMPORARY_EXHIBITIONS 'Temporary Exhibition(s): Check website for exhibits on view(estimated tour time is 30 minutes)' */}
                    <Field
                        name='temporary_exhibition'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.GROUP_TEMPORARY_EXHIBITIONS } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM GROUP_HEADER_OTHER 'Other Information' */}
                        { this.state.boilerplate.GROUP_HEADER_OTHER }
                    </UpperHeader>
                    {/* BOILERPLATE FORM GROUP_GRADE_LEVEL 'Grade Level' */}
                    <Field
                        name='grade_level'
                        options={ this.state.grades }
                        component={ SelectField }
                        label={ this.state.boilerplate.GROUP_GRADE_LEVEL } />
                    <div className='input-group'>
                        {/* BOILERPLATE FORM GROUP_COLLEGE 'College Group' */}
                        <Field
                            name='college_group'
                            component={ CheckBoxField }
                            label={ this.state.boilerplate.GROUP_COLLEGE }
                            toggle={ this.toggleCollege } />
                        {/* BOILERPLATE FORM GROUP_ACADEMIC 'Academic Institution' */}
                        {this.state.showCollege ? (
                            <Field
                                name='academic_institution'
                                type='text'
                                required
                                component={ TextField }
                                label={ this.state.boilerplate.GROUP_ACADEMIC }
                                validate={ [this.required] } />
                        ) : ''}
                    </div>
                    <div className='input-group'>
                        {/* BOILERPLATE FORM GROUP_ADULT 'Adult Group' */}
                        <Field
                            name='adult_group'
                            component={ CheckBoxField }
                            label={ this.state.boilerplate.GROUP_ADULT }
                            toggle={ this.toggleAdultGroup } />
                        {/* BOILERPLATE FORM GROUP_ADULT_ORGANIZATION 'Organization' */}
                        {this.state.showAdultGroup ? (
                            <Field
                                name='organization'
                                type='text'
                                required
                                component={ TextField }
                                label={ this.state.boilerplate.GROUP_ADULT_ORGANIZATION }
                                validate={ [this.required] } />
                        ) : ''}
                    </div>
                    {/* BOILERPLATE FORM GROUP_SPANISH_DOCENT 'Do you need a Spanish speaking docent' */}
                    {/* BOILERPLATE FORM YES 'Yes' */}
                    {/* BOILERPLATE FORM NO 'No' */}
                    <Field
                        name='spanish_docent'
                        label={ this.state.boilerplate.GROUP_SPANISH_DOCENT }
                        component={ RadioGroup }
                        required
                        validate={ [this.required] }
                        options={[
                            { title: this.state.boilerplate.YES, value: 'Yes' },
                            { title: this.state.boilerplate.NO, value: 'No' },
                        ]} />
                </FormGroup>
                <FormGroup>
                    {/* BOILERPLATE FORM GROUP_QUESTIONS 'Question or Comments' */}
                    <Field
                        name='questions_or_comments'
                        component={ TextAreaField }
                        label={ this.state.boilerplate.GROUP_QUESTIONS } />
                </FormGroup>
                <FormGroup>
                    {/* BOILERPLATE FORM GROUP_LEARN_ABOUT 'How did you learn about the Museum?' */}
                    <Field
                        name='how_did_you_learn_about_the_museum'
                        component={ TextAreaField }
                        label={ this.state.boilerplate.GROUP_LEARN_ABOUT } />
                </FormGroup>
                <FormGroup>
                    <div className='form-validation-error'>
                        {error || formErrors ? (
                            <strong>{ error ? error : this.state.boilerplate.GROUP_VALIDATION_ERROR }</strong>
                        ) : ''}
                    </div>
                    <button type='submit' disabled={ submitting } className='button-gradient'>
                        {/* BOILERPLATE FORM GROUP_SUBMIT 'Submit' */}
                        <SubmitText>{ this.state.boilerplate.GROUP_SUBMIT }</SubmitText>
                    </button>
                    <div className='form-error'>
                        {/* BOILERPLATE FORM GROUP_ERROR 'There was an error submitting your request, please try again later.' */}
                        { this.state.boilerplate.GROUP_ERROR }
                    </div>
                </FormGroup>
            </form>
        )
    }
}

const FormGroup = styled.div`
    margin-bottom: 50px;
`

const SubmitText = styled.span`
`


const GroupFormWithError = reduxForm({form: 'groupTourForm'})(GroupTourForm)
const ConnectedGroupFormWithError = connect(state => ({ formData: state.form.groupTourForm }))(GroupFormWithError)

export { ConnectedGroupFormWithError as GroupTourForm }
