import React, { Component } from 'react'

class Success extends Component {
    state = {
        message: this.props.message || '',
    }

    render() {
        return <div className='form-success'>{ this.state.message }</div>
    }
}

export { Success }
