import { createConstants } from '../_helpers/utils'

export const survivorTypes = createConstants(
    'TOGGLE_FILTERS',
    'CHANGE_FILTER',
    'CLEAR_SURVIVOR',
    'CLEAR_SURVIVOR_FILTERS',

    'SURVIVOR_LIST_REQUEST',
    'SURVIVOR_LIST_SUCCESS',

    'SURVIVOR_PAGE_REQUEST',
    'SURVIVOR_PAGE_SUCCESS',

    'UPDATE_POSITION',
)
