import React from 'react'
import styled from 'styled-components'
import { UpperHeader, Image } from '../Common'

const ArrowLink = styled((props) =>
    <div className={ props.className }>
        <ArrowBlock href={ props.href }>
            <TextWrap>
                <UpperHeader>
                    { props.children }
                </UpperHeader>
            </TextWrap>
            <Image src='/static/images/big-arrow.svg' height={ 45 } aria-label={ props.arialabel }/>
        </ArrowBlock>
    </div>
)``

const ArrowBlock = styled.div`
    display: block;
    cursor: pointer;
    text-decoration: none;
    color: black !important;
`

const TextWrap = styled.div`
    margin-bottom: -20px;
`

export { ArrowLink }
