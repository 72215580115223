import React from 'react'
import styled from 'styled-components'
import ReactPlayer from 'react-player'


export const ResponsiveVideo = ({url, autoPlay = false, showControls = true}) => (
    <PlayerWrapper>
        <ReactPlayer
            url={url}
            playing={autoPlay}
            controls={showControls}
            className='react-player'
            width='100%'
            height='100%'
        />
    </PlayerWrapper>
)

const PlayerWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;

    .react-player {
        position: absolute;
        top: 0;
        left: 0;
    }
`