import { survivorTypes } from '../_constants'

const defaultFilters = {
    'name__icontains': {
        active: true,
        value: ''
    },
    'birth_place': {
        active: false,
        value: ''
    },
    'concentration_camp': {
        active: false,
        value: ''
    },
    'immigration_year': {
        active: false,
        value: ''
    },
}

const initialState = {
    list: [],
    page: {},
    filtersActive: false,
    filters: defaultFilters,
    position: {
        x: 0,
        y: 0,
    }
}

export function survivors(state = initialState, action) {
    switch (action.type) {
        case survivorTypes.TOGGLE_FILTERS:
            return {
                ...state,
                filtersActive: !state.filtersActive
            }
        case survivorTypes.CHANGE_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...action.payload
                }
            }
        case survivorTypes.SURVIVOR_LIST_SUCCESS:
            return {
                ...state,
                list: action.payload
            }
        case survivorTypes.SURVIVOR_PAGE_SUCCESS:
            return {
                ...state,
                page: action.payload
            }
        case survivorTypes.CLEAR_SURVIVOR_FILTERS:
            return {
                ...state,
                filters: defaultFilters
            }
        case survivorTypes.CLEAR_SURVIVOR:
            return {
                ...state,
                page: {}
            }
        case survivorTypes.UPDATE_POSITION:
            return {
                ...state,
                position: action.payload
            }
        default:
            return state
    }
}
