import React from 'react'

import styled from 'styled-components'

import {
    Image,
    Em,
    margins,
} from '../Common'


export const Sponsor = ({ sponsors, name, plural, showImage, imageWidth = 100, center = false }) => (
    <div>
        <InfoBlock margintop={30}>
            <Em>{sponsors && sponsors.length > 1 ? plural : name}</Em>
        </InfoBlock>
        {sponsors.map((sponsor, i) => {
            let props = {}
            if (sponsor.url) {
                props.as = 'a'
                props.href = sponsor.url
            }
            return (
                <div key={String(i)}>
                    {sponsor.image && showImage ? (
                        <InfoBlock margintop={10} {...props} center={center} >
                            <SponsorImage src={sponsor.image} role='img' aria-label={`The logo for ${sponsor.name}`} width={imageWidth} />
                        </InfoBlock>
                    ) : (
                        <InfoBlock margintop={5} {...props} center={center} >
                            {sponsor.name}
                        </InfoBlock>
                    )}
                </div>
            )
        })}
    </div>
)

const SponsorImage = styled(Image)`
    max-width: 100%;
    width: ${({ width }) => width}%;
    ${({ width }) => width < 100 && `margin: auto;`}
    height: 100%;
`

const InfoBlock = styled.div`
    ${({ center }) => center ? `
        text-align: center;
    ` : `
        display: flex;
        width: 100%;
    `}
    text-decoration: none;
    color: inherit;
    ${margins}
`
