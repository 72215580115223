import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Field, reduxForm, SubmissionError } from 'redux-form'
import { TextField, TextAreaField, SelectField, CheckBoxField } from './Inputs'
import { postVolunteerForm } from '../../_actions'
import { UpperHeader } from '../Common'

class VolunteerForm extends Component {
    state = {
        showStudent: false,
        languages: this.props.languages || [],
        states: this.props.states || [],
        boilerplate: this.props.boilerplate || {},
    }

    static getDerivedStateFromProps(props, state) {
        if (props.languages && props.languages !== state.languages) state.languages = props.languages
        if (props.states && props.states !== state.states) state.states = props.states
        if (props.boilerplate && props.boilerplate !== state.boilerplate) state.boilerplate = props.boilerplate
        return state
    }

    toggleStudent = show => this.setState({ showStudent: show })

    required = value => {
        {/* BOILERPLATE FORM VOLUNTEER_VALIDATION_REQUIRED 'Required' */}
        return (value ? undefined : this.state.boilerplate.VOLUNTEER_VALIDATION_REQUIRED)
    }

    number = value => {
        {/* BOILERPLATE FORM VOLUNTEER_VALIDATION_NUMBER 'Must be a number' */}
        return value && isNaN(Number(value)) ? this.state.boilerplate.VOLUNTEER_VALIDATION_NUMBER : undefined
    }

    positiveNumber = value => {
        {/* BOILERPLATE FORM VOLUNTEER_VALIDATION_POSITIVE 'Must be a positive number' */}
        return value && value >= 0 ? undefined : this.state.boilerplate.VOLUNTEER_VALIDATION_POSITIVE
    }

    email = value => {
        {/* BOILERPLATE FORM VOLUNTEER_VALIDATION_EMAIL 'Invalid email address' */}
        return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
            ? this.state.boilerplate.VOLUNTEER_VALIDATION_EMAIL
            : undefined
    }

    max250Words = value => {
        {/* BOILERPLATE FORM VOLUNTEER_VALIDATION_MAX_WORDW_250 'Your response cannot be more than 250 words.' */}
        return value && value.split(/ {2}| {1}/g).length - 1 <= 250
            ? undefined
            : this.state.boilerplate.VOLUNTEER_VALIDATION_MAX_WORDW_250
    }

    phoneNumber = value => {
        {/* BOILERPLATE FORM VOLUNTEER_VALIDATION_PHONE 'Invalid phone number, it must be 10 digits' */}
        return value && !/^(0|[1-9][0-9]{9})$/i.test(value)
            ? this.state.boilerplate.VOLUNTEER_VALIDATION_PHONE
            : undefined
    }

    zipcode = value => {
        {/* BOILERPLATE FORM VOLUNTEER_VALIDATION_ZIPCODE 'Invalid zipcode, it must be 5 digits' */}
        return value && !isNaN(Number(value)) && String(value).length === 5
            ? undefined
            : this.state.boilerplate.VOLUNTEER_VALIDATION_ZIPCODE
    }

    validate = values => {
        const errors = {}

        errors.first_name = this.required(values.first_name)
        errors.last_name = this.required(values.last_name)
        errors.address = this.required(values.address)
        errors.city = this.required(values.city)
        errors.state = this.required(values.state)
        errors.zipcode = this.zipcode(values.zipcode)
        errors.email = this.email(values.email)
        errors.primary_phone_number = this.phoneNumber(values.primary_phone_number)
        errors.occupation = this.required(values.occupation)
        errors.emergency_contact_name = this.required(values.emergency_contact_name)
        errors.emergency_contact_phone_number = this.phoneNumber(values.emergency_contact_phone_number)
        errors.emergency_contact_relationship = this.required(values.emergency_contact_relationship)
        if (values.student) {
            errors.student_at = this.required(values.student_at)
            errors.what_grade_or_year = this.required(values.what_grade_or_year)
        }
        errors.highest_level_of_education = this.required(values.highest_level_of_education)
        errors.bilingual = this.required(values.bilingual)
        errors.day_and_times = this.required(values.day_and_times)
        errors.how_often = this.required(values.how_often)
        errors.hear_about_volunteer_opportunities = this.required(values.hear_about_volunteer_opportunities)
        errors.why_volunteer = this.max250Words(values.why_volunteer)
        errors.experience = this.max250Words(values.experience)
        errors.skills = this.max250Words(values.skills)
        errors.reference_1_name = this.required(values.reference_1_name)
        errors.reference_1_email = this.required(values.reference_1_email)
        errors.reference_1_phone_number = this.phoneNumber(values.reference_1_phone_number)
        errors.reference_1_relationship = this.required(values.reference_1_relationship)
        errors.reference_2_name = this.required(values.reference_2_name)
        errors.reference_2_email = this.required(values.reference_2_email)
        errors.reference_2_phone_number = this.phoneNumber(values.reference_2_phone_number)
        errors.reference_2_relationship = this.required(values.reference_2_relationship)
        errors.reference_3_name = this.required(values.reference_3_name)
        errors.reference_3_email = this.required(values.reference_3_email)
        errors.reference_3_phone_number = this.phoneNumber(values.reference_3_phone_number)
        errors.reference_3_relationship = this.required(values.reference_3_relationship)
        errors.more_about_you = this.required(values.more_about_you)

        return errors
    }

    submit = (data) => {
        const { dispatch } = this.props
        const errors = this.validate(data)
        for (const key in errors) {
            {/* BOILERPLATE FORM VOLUNTEER_VALIDATION_ERROR 'Please fill in all required fields.' */}
            const err = errors[key]
            if (err) {
                throw new SubmissionError({
                    [key]: err,
                    _error: this.state.boilerplate.GROUP_VALIDATION_ERROR
                })
            }
        }
        dispatch(postVolunteerForm(data))
    }

    render() {
        const { handleSubmit, error, formData, submitFailed, submitting } = this.props
        const formErrors = submitFailed && formData && formData.syncErrors ? !!Object.keys(formData.syncErrors).length : false
        return (
            <form onSubmit={ handleSubmit(this.submit) }>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM VOLUNTEER_HEADER_PERSONAL_INFORMATION 'Personal Information' */}
                        { this.state.boilerplate.VOLUNTEER_HEADER_PERSONAL_INFORMATION }
                    </UpperHeader>
                    <div className='input-group'>
                        {/* BOILERPLATE FORM VOLUNTEER_FIRST_NAME 'First Name' */}
                        <Field
                            name='first_name'
                            type='text'
                            component={ TextField }
                            label={ this.state.boilerplate.VOLUNTEER_FIRST_NAME }
                            required
                            validate={ [this.required] } />
                        {/* BOILERPLATE FORM VOLUNTEER_LAST_NAME 'Last Name' */}
                        <Field
                            name='last_name'
                            type='text'
                            component={ TextField }
                            label={ this.state.boilerplate.VOLUNTEER_LAST_NAME }
                            required
                            validate={ [this.required] } />
                    </div>
                    {/* BOILERPLATE FORM VOLUNTEER_ADDRESS 'Address' */}
                    <Field
                        name='address'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_ADDRESS }
                        required
                        validate={ [this.required] } />
                    <div className='input-group'>
                        {/* BOILERPLATE FORM VOLUNTEER_CITY 'City' */}
                        <Field
                            name='city'
                            type='text'
                            component={ TextField }
                            label={ this.state.boilerplate.VOLUNTEER_CITY }
                            required
                            validate={ [this.required] } />
                        {/* BOILERPLATE FORM VOLUNTEER_STATE 'State' */}
                        <Field
                            name='state'
                            options={ this.state.states }
                            component={ SelectField }
                            label={ this.state.boilerplate.VOLUNTEER_STATE }
                            required
                            validate={ [this.required] } />
                    </div>
                    {/* BOILERPLATE FORM VOLUNTEER_ZIPCODE 'Zip Code' */}
                    <Field
                        name='zipcode'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_ZIPCODE }
                        required
                        validate={ [this.required, this.zipcode] } />
                    {/* BOILERPLATE FORM VOLUNTEER_CONTACT_EMAIL 'Email Address' */}
                    <Field
                        name='email'
                        type='email'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_CONTACT_EMAIL }
                        required
                        validate={ [this.required, this.email] } />
                    {/* BOILERPLATE FORM VOLUNTEER_PHONE_NUMBER 'Phone Number' */}
                    <Field
                        name='primary_phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_PHONE_NUMBER }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM VOLUNTEER_HEADER_PERSONAL_EMPLOYMENT 'Employment' */}
                        { this.state.boilerplate.VOLUNTEER_HEADER_PERSONAL_EMPLOYMENT }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_EMPLOYER 'Employer' */}
                    <Field
                        name='employer'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_EMPLOYER } />
                    {/* BOILERPLATE FORM VOLUNTEER_OCCUPATION 'Occupation' */}
                    <Field
                        name='occupation'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_OCCUPATION }
                        required
                        validate={ [this.required] } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM VOLUNTEER_HEADER_EMERGENCY_CONTACT 'Emergency Conctact' */}
                        { this.state.boilerplate.VOLUNTEER_HEADER_EMERGENCY_CONTACT }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_EMERGENCY_CONTACT_NAME 'Name' */}
                    <Field
                        name='emergency_contact_name'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_EMERGENCY_CONTACT_NAME }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM VOLUNTEER_EMERGENCY_CONTACT_PHONE 'Phone Number' */}
                    <Field
                        name='emergency_contact_phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_EMERGENCY_CONTACT_PHONE }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                    {/* BOILERPLATE FORM VOLUNTEER_EMERGENCY_CONTACT_RELATIONSHIP 'Relationship' */}
                    <Field
                        name='emergency_contact_relationship'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_EMERGENCY_CONTACT_RELATIONSHIP }
                        required
                        validate={ [this.required] } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM VOLUNTEER_HEADER_EDUCATION 'Education' */}
                        { this.state.boilerplate.VOLUNTEER_HEADER_EDUCATION }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_STUDENT 'Are you a student?' */}
                    <Field
                        name='student'
                        type='checkbox'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.VOLUNTEER_STUDENT }
                        toggle={ this.toggleStudent } />
                    {this.state.showStudent ? (
                        <div className='input-group'>
                            {/* BOILERPLATE FORM VOLUNTEER_STUDENT_WHERE 'Where?' */}
                            <Field
                                name='student_at'
                                component={ TextField }
                                label={ this.state.boilerplate.VOLUNTEER_STUDENT_WHERE }
                                required
                                validate={ [this.required] } />
                            {/* BOILERPLATE FORM VOLUNTEER_STUDENT_GRADE 'What grade/year?' */}
                            <Field
                                name='what_grade_or_year'
                                component={ TextField }
                                label={ this.state.boilerplate.VOLUNTEER_STUDENT_GRADE }
                                required
                                validate={ [this.required] } />
                        </div>
                    ) : ''}
                    {/* BOILERPLATE FORM VOLUNTEER_HIGHEST_EDUCATION 'Highest level of education completed?' */}
                    <Field
                        name='highest_level_of_education'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_HIGHEST_EDUCATION }
                        required
                        validate={ [this.required] } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM VOLUNTEER_HEADER_LANGUAGE 'Lanuages' */}
                        { this.state.boilerplate.VOLUNTEER_HEADER_LANGUAGE }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_BILINGUAL 'Are you bilingual in English and Spanish?' */}
                    <Field
                        name='bilingual'
                        options={ this.state.languages }
                        component={ SelectField }
                        label={ this.state.boilerplate.VOLUNTEER_BILINGUAL }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM VOLUNTEER_FLUENT 'Are you fluent in other languages?' */}
                    <Field
                        name='other_languages'
                        options={ this.state.languages }
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_FLUENT } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM VOLUNTEER_HEADER_AVAILABILITY 'Availability' */}
                        { this.state.boilerplate.VOLUNTEER_HEADER_AVAILABILITY }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_DAY_AND_TIMES 'What days of the week and times within those days are you available to volunteer?' */}
                    <Field
                        name='day_and_times'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_DAY_AND_TIMES }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM VOLUNTEER_HOW_OFTEN 'How often would you like to volunteer?' */}
                    <Field
                        name='how_often'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_HOW_OFTEN }
                        required
                        validate={ [this.required] } />
                    <UpperHeader marginbottom={ 10 } className='secondary-label no-transform'>
                        {/* BOILERPLATE FORM VOLUNTEER_TYPE_OF_WORK 'What type of volunteer work are you interested in?' */}
                        { this.state.boilerplate.VOLUNTEER_TYPE_OF_WORK }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_ADMINISTRATIVE 'Administrative' */}
                    <Field
                        name='administrative'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.VOLUNTEER_ADMINISTRATIVE } />
                    {/* BOILERPLATE FORM VOLUNTEER_ARCHIVES_LIBRARY 'Archives/Library' */}
                    <Field
                        name='archives_library'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.VOLUNTEER_ARCHIVES_LIBRARY } />
                    {/* BOILERPLATE FORM VOLUNTEER_DEVELOPMENT 'Development' */}
                    <Field
                        name='development'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.VOLUNTEER_DEVELOPMENT } />
                    {/* BOILERPLATE FORM VOLUNTEER_DOCENT 'Docent' */}
                    <Field
                        name='docent'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.VOLUNTEER_DOCENT } />
                    {/* BOILERPLATE FORM VOLUNTEER_EDUCATION 'Education' */}
                    <Field
                        name='education'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.VOLUNTEER_EDUCATION } />
                    {/* BOILERPLATE FORM VOLUNTEER_FRONT_DESK 'Front Desk' */}
                    <Field
                        name='front_desk'
                        component={ CheckBoxField }
                        label={ this.state.boilerplate.VOLUNTEER_FRONT_DESK }/>
                    {/* BOILERPLATE FORM VOLUNTEER_OTHER 'Other' */}
                    <Field
                        name='other'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_OTHER } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM VOLUNTEER_HEADER_EXPERIENCE 'Experience and Background' */}
                        { this.state.boilerplate.VOLUNTEER_HEADER_EXPERIENCE }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_HEAR_ABOUT 'How did you hear about our volunteer opportunities?' */}
                    <Field
                        name='hear_about_volunteer_opportunities'
                        component={ TextAreaField }
                        label={ this.state.boilerplate.VOLUNTEER_HEAR_ABOUT }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM VOLUNTEER_WHY 'Why do you want to volunteer with Holocaust Museum Houston?' */}
                    <Field
                        name='why_volunteer'
                        component={ TextAreaField }
                        label={ this.state.boilerplate.VOLUNTEER_WHY }
                        maxWords={ 250 }
                        required
                        validate={ [this.required, this.max250Words] } />
                    {/* BOILERPLATE FORM VOLUNTEER_TOTAL_EXPERIENCE 'Please list any volunteer experiences you have had in the past three years and/or any pertaining to the Museum’s subject matter.' */}
                    <Field
                        name='experience'
                        component={ TextAreaField }
                        label={ this.state.boilerplate.VOLUNTEER_TOTAL_EXPERIENCE }
                        maxWords={ 250 }
                        required
                        validate={ [this.required, this.max250Words] } />
                    {/* BOILERPLATE FORM VOLUNTEER_SKILLS 'What skills, training or knowledge do you have that can be utilized at the Museum?' */}
                    <Field
                        name='skills'
                        component={ TextAreaField }
                        label={ this.state.boilerplate.VOLUNTEER_SKILLS }
                        maxWords={ 250 }
                        required
                        validate={ [this.required, this.max250Words] } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM VOLUNTEER_HEADER_REFERENCES 'References' */}
                        { this.state.boilerplate.VOLUNTEER_HEADER_OTHER }
                    </UpperHeader>
                    <UpperHeader marginbottom={ 10 } className='secondary-label'>
                        {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_1 'Reference #1' */}
                        { this.state.boilerplate.VOLUNTEER_REFERENCE_1 }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_NAME 'Name' */}
                    <Field
                        name='reference_1_name'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_NAME }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_EMAIL 'Email' */}
                    <Field
                        name='reference_1_email'
                        type='email'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_EMAIL }
                        required
                        validate={ [this.required, this.email] } />
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_PHONE 'Phone Number' */}
                    <Field
                        name='reference_1_phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_PHONE }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_RELATIONSHIP 'Relationship' */}
                    <Field
                        name='reference_1_relationship'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_RELATIONSHIP }
                        required
                        validate={ [this.required] } />
                    <UpperHeader marginbottom={ 10 } className='secondary-label'>
                        {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_2 'Reference #2' */}
                        { this.state.boilerplate.VOLUNTEER_REFERENCE_2 }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_NAME 'Name' */}
                    <Field
                        name='reference_2_name'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_NAME }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_EMAIL 'Email' */}
                    <Field
                        name='reference_2_email'
                        type='email'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_EMAIL }
                        required
                        validate={ [this.required, this.email] } />
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_PHONE 'Phone Number' */}
                    <Field
                        name='reference_2_phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_PHONE }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_RELATIONSHIP 'Relationship' */}
                    <Field
                        name='reference_2_relationship'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_RELATIONSHIP }
                        required
                        validate={ [this.required] } />
                    <UpperHeader marginbottom={ 10 } className='secondary-label'>
                        {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_3 'Reference #3' */}
                        { this.state.boilerplate.VOLUNTEER_REFERENCE_3 }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_NAME 'Name' */}
                    <Field
                        name='reference_3_name'
                        type='text'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_NAME }
                        required
                        validate={ [this.required] } />
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_EMAIL 'Email' */}
                    <Field
                        name='reference_3_email'
                        type='email'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_EMAIL }
                        required
                        validate={ [this.required, this.email] } />
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_PHONE 'Phone Number' */}
                    <Field
                        name='reference_3_phone_number'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_PHONE }
                        required
                        validate={ [this.required, this.phoneNumber] } />
                    {/* BOILERPLATE FORM VOLUNTEER_REFERENCE_RELATIONSHIP 'Relationship' */}
                    <Field
                        name='reference_3_relationship'
                        type='tel'
                        component={ TextField }
                        label={ this.state.boilerplate.VOLUNTEER_REFERENCE_RELATIONSHIP }
                        required
                        validate={ [this.required] } />
                </FormGroup>
                <FormGroup>
                    <UpperHeader marginbottom={ 20 }>
                        {/* BOILERPLATE FORM VOLUNTEER_HEADER_REFERENCES 'Additional Information' */}
                        { this.state.boilerplate.VOLUNTEER_HEADER_OTHER }
                    </UpperHeader>
                    {/* BOILERPLATE FORM VOLUNTEER_MORE_ABOUT_YOU 'Please list anything else you would like us to know about you' */}
                    <Field
                        name='more_about_you'
                        component={ TextAreaField }
                        required
                        label={ this.state.boilerplate.VOLUNTEER_MORE_ABOUT_YOU }
                        maxWords={ 250 }
                        validate={ [this.required, this.max250Words] } />
                </FormGroup>
                <FormGroup>
                    <div className='form-validation-error'>
                        {error || formErrors ? (
                            <strong>{ error ? error : this.state.boilerplate.GROUP_VALIDATION_ERROR }</strong>
                        ) : ''}
                    </div>
                    <button type='submit' disabled={ submitting } className='button-gradient'>
                        {/* BOILERPLATE FORM VOLUNTEER_SUBMIT 'Submit Application' */}
                        <SubmitText>{ this.state.boilerplate.VOLUNTEER_SUBMIT }</SubmitText>
                    </button>
                    <div className='form-error'>
                        {/* BOILERPLATE FORM VOLUNTEER_ERROR 'There was an error submitting your request, please try again later.' */}
                        { this.state.boilerplate.VOLUNTEER_ERROR }
                    </div>
                </FormGroup>
            </form>
        )
    }
}

const FormGroup = styled.div`
    margin-bottom: 50px;
`

const SubmitText = styled.span`
`

const VolunteerFormWithError = reduxForm({form: 'volunteerForm'})(VolunteerForm)
const ConnectedVolunteerFormWithError = connect(state => ({ formData: state.form.volunteerForm }))(VolunteerFormWithError)

export { ConnectedVolunteerFormWithError as VolunteerForm }
