import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import DocumentMeta from 'react-document-meta'

class NotFound extends Component {
    render() {
        let { boilerplate } = this.props
        boilerplate = boilerplate.NAV || boilerplate
        {/* BOILERPLATE NAV NOT_FOUND 'Not Found' */}
        const meta = {
            title: `HMH | ${boilerplate.NOT_FOUND}`,
        }

        return (
            <NotFoundContainer className='content' id='not-found'>
                <DocumentMeta { ...meta } />
                {/* BOILERPLATE NAV NOT_FOUND_TEXT '<h2>Not Found</h2><p>The page you&apos;re looking for does not exist.</p>' */}
                <div dangerouslySetInnerHTML={{ __html: boilerplate.NOT_FOUND_TEXT }} />
            </NotFoundContainer>
        )
    }
}

function mapStateToProps(state) {
    return {
        boilerplate: state.content.boilerplate,
    }
}

const connectedNotFound = connect(mapStateToProps)(NotFound)
export { connectedNotFound as NotFound }

const NotFoundContainer = styled.div`
    height: calc(100vh - 435px);
    display: flex;
    justify-content: center;
    align-items: center;
`
