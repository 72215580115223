import React from 'react'

const TextAreaField = ({ input, label, type, placeholder, required, maxWords, meta: { touched, error } }) => (
    <div className='input-wrap text-area'>
        <label>
            { label }{ required ? '*' : '' } { maxWords ? `(${input.value.split(/ {2}| {1}/g).length - 1} of ${maxWords} words)` : '' } { touched && error && <span>{ error }</span> }
        </label>
        <div className='input-container'>
            <textarea { ...input } placeholder={ placeholder } type={ type } />
        </div>
    </div>
)

export { TextAreaField }
