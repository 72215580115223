import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
    UpperHeader,
    H3Header,
    ItalicLink,
    ImageCard,
    ArticleTextWrap,
    Header,
    Block,
    Button,
    Hr,
    gradient,
    ArrowLink,
    Grid,
    devices,
} from '../Common'
import {
    Biography,
    QA,
    SimpleCarousel,
    Table,
    ThumbnailGrid,
    ThreeColumnsVisit,
    ThreeColumnsEducation,
    Info,
} from '../Content'
import LazyLoad from 'react-lazy-load'
import colors from '../../css/colors.scss'

const BlockComp = ({ pageBlock, id, imageHeight, textPadding, LinkComp, ie, columns}) => (
    <FlexColumn
        key={ `#${id}` }
        margintop={ 30 }
        href={ pageBlock[`external_url${id}`] }
        to={ pageBlock[`linked_page${id}`] ? pageBlock[`linked_page${id}`].url : '' }
        as={ pageBlock[`linked_page${id}`] ? Link : 'a' }
        ie={ ie ? 1 : 0 }
        marginright={ Number(id) === columns ? 0 : 15 }
        title={ pageBlock[`link_title${id}`] || pageBlock[`small_title${id}`] || pageBlock[`linked_subpage_description${id}`] }
    >
        {pageBlock[`image${id}`] ? (
            <LazyLoad height={ imageHeight } offset={ 100 }>
                <ImageCard background={ pageBlock[`image${id}`] } height={ imageHeight } aria-label={ `Image of ${pageBlock[`link_title${id}`] || pageBlock[`small_title${id}`] || pageBlock[`linked_subpage_description${id}`]}` } />
            </LazyLoad>
        ) : ''}
        <TextContent paddingRight={ textPadding }>
            {pageBlock[`small_title${id}`] ? (
                <UpperHeader>
                    { pageBlock[`small_title${id}`] }
                </UpperHeader>
            ) : ''}
            {pageBlock[`link_title${id}`] ? (
                <H3Header>
                    { pageBlock[`link_title${id}`] }
                </H3Header>
            ) : ''}
            {pageBlock[`content${id}`] ? (
                <Block paddingbottom={ 20 } dangerouslySetInnerHTML={{ __html: pageBlock[`content${id}`] }} />
            ) : ''}
            {pageBlock[`linked_subpage_description${id}`] ? (
                <LinkComp as='div'>{ pageBlock[`linked_subpage_description${id}`] }</LinkComp>
            ) : ''}
        </TextContent>
    </FlexColumn>
)

const ArticleContent = ({ pageBlock, siblings, iframeHeight, loadIframe }) => (
    <React.Fragment>
        {pageBlock.type === 'LEFT' && pageBlock.title ? (
            <UpperHeader margintop={ 0 } marginbottom={ 20 }>{ pageBlock.title }</UpperHeader>
        ) : ''}
        {pageBlock.image ? (
            <ImageCard background={ pageBlock.image } />
        ) : ''}
        {pageBlock.small_title && pageBlock.id !== 43 ? (
            <UpperHeader marginbottom={ 20 }>
                { pageBlock.small_title }
            </UpperHeader>
        ) : ''}
        <ArticleTextWrap
            shorten={ !siblings }
            dangerouslySetInnerHTML={{ __html: pageBlock.content }}
            className={ (location.pathname.includes('about/boards-and-leadership') || location.pathname.includes('acerca/juntas-directivas-liderazgo')) && pageBlock.content.includes('cke-block') ? 'flex-columns' : '' }
        />
        {pageBlock.iframe_url ? (
            <iframe
                src={ pageBlock.iframe_url }
                width='100%'
                height={ iframeHeight }
                scrolling='auto'
                frameBorder='0'
                style={{ maxWidth: '740px', marginTop: '15px' }}
                onLoad={ loadIframe }
            />
        ) : ''}
    </React.Fragment>
)

export const PageBlock = ({ pageBlock, siblings, url, ie }) => {
    const [iframeHeight, setIframeHeight] = useState(400)
    const [iframeLoaded, setIframeLoaded] = useState(false)

    const loadIframe = () => {
        if (iframeLoaded) {
            setIframeHeight(900)
        }
        setIframeLoaded(true)
    }

    let max_width = '700px'
    if (pageBlock.block_format === 'TABLE' ||
        pageBlock.block_format === 'THUMBNAIL-GRID' ||
        pageBlock.block_format === '4-COLUMN' ||
        pageBlock.block_format === '3-COLUMN' ||
        pageBlock.block_format === '2-COLUMN' ||
        pageBlock.block_format === '3-COLUMN-TEXT'
    ) {
        max_width = '1400px'
    }
    if (url === '/') {
        max_width = 'unset'
    }

    return (
        <DynamicWidthBlock
            marginbottom={pageBlock.block_format === 'STICKY_INFO' ? 0 : 15}
            maxwidth={max_width}
        >
            {pageBlock.hash_url ? <div id={pageBlock.hash_url.toLowerCase().replace(/ /g, '_')} /> : ''}
            {pageBlock.block_format === 'HR' && <Hr />}
            {pageBlock.type === 'LEFT' && pageBlock.block_format !== 'STICKY_INFO' && pageBlock.title && pageBlock.block_format === '4-COLUMN' ? (
                <Header margintop={0} marginbottom={20}>{pageBlock.title}</Header>
            ) : ''}
            {pageBlock.block_format === 'STICKY_INFO' && pageBlock.title ? (
                <H3Header marginbottom={20}>{pageBlock.title}</H3Header>
            ) : ''}
            {
                pageBlock.block_format === 'CAROUSEL' ? <SimpleCarousel slides={pageBlock.carousel} content={pageBlock.content} smallTitle={pageBlock.small_title} /> : ''
            }
            {
                pageBlock.block_format === 'BIO' ? <Biography subBlocks={pageBlock.bio} title={pageBlock.small_title} /> : ''
            }
            {
                pageBlock.block_format === 'STICKY_INFO' ? <Info subBlocks={pageBlock.info} intro={pageBlock.content} /> : ''
            }
            {
                pageBlock.block_format === 'THUMBNAIL-GRID' ? <ThumbnailGrid subBlocks={pageBlock.sub_block} /> : ''
            }
            {pageBlock.block_format === 'QA' ? (
                <div>
                    <UpperHeader marginbottom='15'>{pageBlock.title}</UpperHeader>
                    {pageBlock.qa.map(e => <QA qa={e} key={e.question} />)}
                </div>
            ) : ''}
            {pageBlock.block_format === '3-COLUMN-TEXT' ? (
                url === '/visit/' || url === '/visite/' ? (
                    <ThreeColumnsVisit block={pageBlock} />
                ) : (
                        <ThreeColumnsEducation block={pageBlock} />
                    )
            ) : ''}
            {
                pageBlock.block_format === 'TABLE' ? <Table subBlocks={pageBlock.table} /> : ''
            }
            {pageBlock.block_format === 'BUTTON' ? (
                <LargeButtonWrap>
                    {pageBlock.linked_page ? (
                        <LargeButton as={Link} to={pageBlock.linked_page.url}>
                            {pageBlock.link_title}
                        </LargeButton>
                    ) : ''}
                </LargeButtonWrap>
            ) : ''}
            {pageBlock.block_format === '1-COLUMN' ? (
                <ArticleContent
                    pageBlock={pageBlock}
                    siblings={siblings}
                    iframeHeight={iframeHeight}
                    loadIframe={loadIframe}
                    columns={1}
                />
            ) : ''}
            {pageBlock.block_format === '2-COLUMN' ? (
                <Grid columns={2} ie={ie} totalmargin={15}>
                    {['', '2'].map(id => (
                        <BlockComp ie={ie}
                            key={`#${id}`}
                            id={id}
                            pageBlock={pageBlock}
                            imageHeight={437}
                            textPadding={'20%'}
                            LinkComp={url === '/' ? ArrowLink : ItalicLink}
                            columns={2}
                        />
                    ))}
                </Grid>
            ) : ''}
            {pageBlock.block_format === '3-COLUMN' ? (
                <Grid columns={3} ie={ie} totalmargin={30}>
                    {['', '2', '3'].map(id => (
                        <BlockComp ie={ie}
                            key={`#${id}`}
                            id={id}
                            pageBlock={pageBlock}
                            imageHeight={400}
                            LinkComp={ItalicLink}
                            url={url}
                            columns={3}
                        />
                    ))}
                </Grid>
            ) : ''}
            {pageBlock.block_format === '4-COLUMN' ? (
                <Grid columns={window.innerWidth < 800 ? 2 : 4} totalmargin={window.innerWidth < 800 ? 30 : 15 * 3} ie={ie}>
                    {['', '2', '3', '4'].map(id => (
                        <BlockComp ie={ie}
                            key={`#${id}`}
                            id={id}
                            pageBlock={pageBlock}
                            imageHeight={381}
                            LinkComp={ItalicLink}
                            columns={4}
                        />
                    ))}
                </Grid>
            ) : ''}
        </DynamicWidthBlock>
    )
}

const FlexColumn = styled(Grid.Column)`
    display: ${props => props.ie ? 'block' : 'flex'};
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    margin-right: ${props => props.marginright}px;
    @media ${devices.mobile} {
        margin-right: 0;
    }
`

const DynamicWidthBlock = styled(Block)`
    max-width: ${props => props.maxwidth};
`

const TextContent = styled.article`
    padding-top: 1rem;
    padding-right: ${({ paddingRight='2rem' }) => paddingRight};
    flex: 1;
    display: flex;
    flex-direction: column;
    .before-top {
        margin-bottom: auto;
    }
    ${ArrowLink} {
        margin-top: auto;
    }
`

const LargeButtonWrap = styled(Block)`
    text-align: center;
    margin-top: 40px;
    margin-bottom: 10px;
`

const LargeButton = styled(Button)`
    font-size: 16px;
    padding: 8px 40px 10px;
    display: inline-block;
    line-height: 16px;
    height: auto;
    box-shadow: none;
    background-color: ${colors.white};
    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: -1px;
        z-index: -1;
        ${gradient}
    }
`