import React, { useState } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'
import { minDevices } from '../Common'
import colors from '../../css/colors.scss'
import { useEventListener } from '../../_helpers'

export const ScrollTopButton = () => {
    const [showImageButton, setShowImageButton] = useState(false)
    let { boilerplate } = useSelector(state => state.content, shallowEqual)

    const onScroll = () => {
        const scrollHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight)
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        const height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0)
        if (scrollTop > (height + 500) && (scrollTop + height) < (scrollHeight - 54)) {
            setShowImageButton(true)
        } else {
            setShowImageButton(false)
        }
    }

    useEventListener('scroll', onScroll)

    const handleScrollTop = () => {
        const scroll = (position) => {
            if (position > 0) {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                })
            }
        }
        const offset = document.documentElement.scrollTop || document.body.scrollTop
        scroll(offset)
    }

    boilerplate = boilerplate.NAV || boilerplate

    return (
        <React.Fragment>
            <ImageButtonContainer show={showImageButton} onClick={handleScrollTop}>
                <ImageButton />
            </ImageButtonContainer>
            <TextButton onClick={handleScrollTop}>
                {/* BOILERPLATE NAV BACK_TO_TOP 'Back to top' */}
                {boilerplate.BACK_TO_TOP}
            </TextButton>
        </React.Fragment>
    )
}

const MobileOnly = styled.div`
    @media ${minDevices.mobile} {
        display: none;
    }
`

const TextButton = styled(MobileOnly)`
    width: 100%;
    padding: 11px;
    text-align: center;
    text-transform: uppercase;
    background-color: #ccdfe4;
    color: ${colors.blue};
    cursor: pointer;
`

const ImageButton = styled(MobileOnly)`
    height: 40px;
    width: 32px;
    background: center / contain no-repeat url('/static/images/scroll-top-arrow.svg');
`

const ImageButtonContainer = styled.div`
    height: 60px;
    width: 60px;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    justify-content: center;
    align-items: center;
    background: rgba(${colors.white}, 0.77);
    border-radius: 50%;
    display: ${({ show }) => show ? 'flex' : 'none'};
`
